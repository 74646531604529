import Form from './Form';
import ocarzProLogo from '../../assets/images/ocarz-pro.svg';
import switchyproLogo from '../../assets/images/switchy.png';
import loginFormBg from '../../assets/images/bg-form.png';
import {Link} from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher';

function LoginForm() {
  const OCARZPRO = process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro';
  const SWITCHYPRO =  process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro';

  return (
    <div className="flex items-center justify-center flex-col md:py-18 h-full"
      style={{background: `url(${loginFormBg}) no-repeat center center/cover`}}>
      <div className="absolute top-0 right-0 p-10">
        <LanguageSwitcher />
      </div>
      <div className="lg:mb-22 my-24">
        <Link to="/">
          <img src={SWITCHYPRO && switchyproLogo || OCARZPRO && ocarzProLogo} className="mx-auto max-w-[300px]" alt=""/>
        </Link>
      </div>
      <Form/>
    </div>
  );
}

export default LoginForm;
