import LoginThumb from '../components/security/LoginThumb';
import LoginForm from '../components/security/LoginForm';
import { Navigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectToken} from '../features/auth/authSlice';

function LoginPage() {
  const token = useSelector(selectToken);
  if(token) {
    return <Navigate to='/products'/>;
  }

  return (
    <div className="grid grid-cols-12">
      <div className="h-full min-h-full order-last col-span-12 md:order-first md:col-span-7 lg:col-span-8 bg-white flex content-center">
        <LoginThumb />
      </div>
      <div className="col-span-12 md:col-span-5 lg:col-span-4">
        <LoginForm />
      </div>
    </div>
  );
}

export default LoginPage;
