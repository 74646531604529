import {useEffect, useState} from 'react';
import HeroSlider from '../components/homepage/HeroSlider';
import ProductCardDetails from '../components/product/ProductCardDetails';
import ProductMainInfo from '../components/product/ProductMainInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {useNavigate, useParams} from 'react-router-dom';
import Header from '../components/common/Header';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCreditAsync} from '../features/credit/creditSlice';
import {selectMe} from '../features/me/meSlice';
import {fetchOwner, fetchProduct} from '../features/products/productAPI';
import TechnicalSheet from '../components/product/TechnicalSheet';
import CarEquipment from '../components/product/CarEquipment';
import {useTranslation} from 'react-i18next';

export default function ProductDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [owner, setOwner] = useState(null);
  const dispatch = useDispatch();
  const me = useSelector(selectMe);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchProduct(params.id);
      if (response.status === 200) {
        setProduct(response.data);
      }
    };
    fetchData();
  }, [params]);

  const getOwner = async () => {
    const response = await fetchOwner(`${params.id}/owner`);
    if (response.status === 200) {
      setOwner(response.data);
    }
    if (me && me.credit) {
      dispatch(fetchCreditAsync(me.credit));
    }
  };

  useEffect(() => {
    if (!product) return;
    getOwner();
  }, [product]);

  const images = product && product.images ? product.images.reduce((paths, image) => {
    paths.push(image.contentUrl);
    return paths;
  }, []) : [];

  const handleOnContact = () => {
    getOwner();
  };

  const {t} = useTranslation();
  return (
    <>
      <Header/>
      <div className="bg-white sm:ml-0 md:ml-10 pt-5 rounded-tl-lg">
        <div className="container">
          <div className="px-4">
            <button
              className="font-montserrat text-gray-500 font-semibold text-base flex items-center gap-2 mb-5"
              onClick={() => navigate('/products')}><FontAwesomeIcon icon={faChevronLeft}/>
              {t('translation.back')}
            </button>
            {product ?
              <>
                <div className="flex flex-col lg:flex-row gap-5">
                  <div className="lg:block xl:flex xl:flex-row xl:items-stretch lg:gap-2 lg:basis-[75%] xl:basis-[60%] 2xl:basis-[70%] py-5">
                    <HeroSlider images={images} videoUrl={product.videoUrl}
                      flexBasis="lg:basis-[80%] basis-[100%]"/>
                    <ProductMainInfo flexBasis="lg:basis-[20%] sm:basis-[100%]"
                      transmission={product.transmission}
                      mileage={product.mileage}
                      year={product.year}
                      engine={product.engine}
                      fuel={product.fuel}
                      brand={product.brand ? product.brand.name : null}
                      storageCapacity={product.storageCapacity}
                      color={product.color}
                      firstHand={product.firstHand}
                    />
                  </div>
                  <ProductCardDetails
                    flexBasis="lg:basis-[35%] sm:basis-[50%] 2xl:basis-[30%]"
                    productIri={product['@id']}
                    title={product.title}
                    model={product.model.name}
                    price={product.price} owner={owner} onContact={handleOnContact}/>
                </div>

              </> :
              <p>Loading...</p>
            }
          </div>
          {product && product.specifications &&
              <>
                <TechnicalSheet specs={product.specifications}/>
                <CarEquipment equipment={product.specifications}/>
              </>
          }
        </div>
      </div>
    </>
  );
}
