import carPeople from '../../assets/images/car-peoples.png';
import switchyproMediaImg from '../../assets/images/timing-is-key.png';
import market1Img from '../../assets/images/to-market1.png';
import market2Img from '../../assets/images/to-market2.png';
import market3Img from '../../assets/images/to-market3.png';
import market4Img from '../../assets/images/to-market4.png';
import PhoneBadge from '../../assets/images/phone-badge.png';
import parse from 'html-react-parser';

import {useTranslation} from 'react-i18next';


function MediaSection() {
  const TARGETED_PLATFORM =  process.env.REACT_APP_TARGETED_PLATFORM;
  const OCARZPRO =  process.env.REACT_APP_TARGETED_PLATFORM.toLowerCase() === 'ocarzpro';
  const SWITCHYPRO =  process.env.REACT_APP_TARGETED_PLATFORM.toLowerCase() ===  'switchypro';
  const {t} = useTranslation();

  return (
    <div >
      <div className="bg-media-car-people text-black flex sm:flex-row flex-col justify-center items-center gap-4 sm:p-10 p-5">
        <p className="font-axis-bold sm:text-right text-left text-lg px-4 sm:w-[35%] w-full">
          {parse(t(`${TARGETED_PLATFORM}.peopleCarDesc`))}
        </p>
        <img src={OCARZPRO && carPeople || SWITCHYPRO && switchyproMediaImg} alt=""/>
      </div>
      <div className="bg-white mx-auto block text-center py-10">
        <h1 className="font-axis-bold text-black pb-10 text-2xl">
          {t(`${TARGETED_PLATFORM}.timeToMarket`)}
        </h1>
        <div className="flex justify-center items-center gap-6 px-4 sm:px-24 lg:px-32">
          {OCARZPRO && 
            <>
              <img src={market1Img} alt="" className="col-span-2 sm:col-span-1"/>
              <img src={market2Img} alt="" className="col-span-2 sm:col-span-1"/>
              <img src={market3Img} alt="" className="col-span-2 sm:col-span-1"/>
              <img src={market4Img} alt="" className="col-span-2 sm:col-span-1"/>
            </>
          }

          {SWITCHYPRO &&
              <>
                <div className="relative max-w-max">
                  <span className="text-white text-xs  uppercase absolute left-1/2 top-0 -translate-x-1/2 pt-[6px] font-bold font-montserrat">{t(`${TARGETED_PLATFORM}.immediately`)}</span>
                  <img src={PhoneBadge} alt=""/>
                </div>
                <div className="relative max-w-max">
                  <span className="text-white text-xs  uppercase absolute left-1/2 top-0 -translate-x-1/2 pt-[6px] font-bold font-montserrat">{t(`${TARGETED_PLATFORM}.thirteenDays`)}</span>
                  <img src={PhoneBadge} alt=""/>
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
}

export default MediaSection;
