import axiosInstance, {API_BASE_URL} from '../../services/axiosInstance';
import axios from 'axios';

export const fetchProducts = async (url) => {
  return axiosInstance.get(url);
};

export const fetchProduct = (iri) => {
  return axiosInstance.get(iri);
};

export const fetchOwner = (iri) => {
  return axiosInstance.get(iri);
};

export const fetchLatestProducts = async () => {
  try {
    return (await axios.get(`${API_BASE_URL}/products/latest`)).data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchProductOwnerContactInfo = async (productIri, type) => {
  return axiosInstance({
    method: 'GET',
    url: `${productIri}/owner?type=${type}`,
  });
};

export const addProductsToCampaign = async (campaignIri, filters) => {
  return axiosInstance({
    method: 'POST',
    url: '/add_products_to_campaign_actions',
    data: JSON.stringify({
      ...filters,
      campaign: campaignIri,
    }),
  });
};
