import Header from '../components/common/Header';
import ProfileInfo from '../components/ProfileInfo';

function Profile() {
  return (
    <>
      <Header/>
      <ProfileInfo />
    </>
  );
}

export default Profile;
