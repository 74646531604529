import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import {useTranslation} from 'react-i18next';

const CarEquipment = ({equipment}) => {
  const [data, setData] = useState(null);
  const {t} = useTranslation();
    
  useEffect(() => {
    const equipmentFiltred = equipment.filter(spec => spec.key.includes('equipmant'));

    if(equipmentFiltred.length !== 0) {
      setData(equipmentFiltred[0].data);
    }
  },[]);

  return (
    <div className="px-4">
      {data &&
        <>
          <h1 className="text-2xl font-semibold font-montserrat mb-4">{t('translation.equipment')}</h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-10 gap-x-10 gap-y-4">
            {data && data.map(({key, label, value}) =>
              <p key={key} className="border-b-2 border-[#1ea0fb] flex justify-start gap-x-2 text-black mr-4 pb-2">
                {value && <CheckIcon sx={{ color: '#1ea0fb' }} size="small" />}
                <span className="">{label}</span>
              </p>
            )}
          </div>
        </>
      }
      
    </div>
  );
};

export default CarEquipment;

CarEquipment.propTypes = {
  equipment: PropTypes.array.isRequired,
};
