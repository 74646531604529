import {useForm} from 'react-hook-form';
import VideoDropzone from './VideoDropzone';
import {useEffect, useRef, useState} from 'react';
import ImageDropzone from './ImageDropzone';
import {uploadImage, uploadVideo} from '../features/uploadFiles/UploadFilesAPI';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchCampaignById, updateCampaign} from '../features/campaign/campaignAPI';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

export const ChannelsForm = () => {
  const {t} = useTranslation();
  const [campaign, setCampaign] = useState({});
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [channel, setChannel] = useState('');
  const [messageMaxLength, setMessageMaxLength] = useState('');
  const navigate = useNavigate();
  const uploadFormRef = useRef();
  const {id} = useParams();
  



  const {register, setValue,handleSubmit,formState: {errors}} = useForm({
  });
  setValue('name', campaign.name);  
  setValue('channel', campaign.channel);  
  setValue('startTime', campaign.startTime);  
  setValue('endTime', campaign.endTime);  
  setValue('message', campaign.message);  
  setValue('photos', campaign.photos);  
  setValue('videos', campaign.videos);  



  const handleImageChange = (image) => {
    setImages(image);
  };

  const handleVideoChange = (video) => {
    setVideos(video);
  };

  const handleChannelChange = () => {
    const currentChannel = uploadFormRef.current['channel'].value;
    setChannel(currentChannel);
    currentChannel === 'whatsapp' ? setShowUpload(!showUpload) : setShowUpload(false);
  };

  const handleMaxLengthOnChannelChange = () => {
    if (channel === 'whatsapp') {
      setMessageMaxLength('255');
    } else if (channel === 'sms') {
      setMessageMaxLength('160');
    } else {
      setMessageMaxLength('');
    }
    return messageMaxLength;
  };

  useEffect(() => {
    handleMaxLengthOnChannelChange();
  });

  useEffect(() => {
    const fetchCampaign = async () => {
      return await fetchCampaignById(id);

    };
    fetchCampaign().then(campaign => {
      setCampaign(campaign);
      setChannel(campaign.channel);
      handleChannelChange();
    });
  }, [id]);

  const onSubmit = async (data) => {

    const imagesIri = [...campaign.photos];
    const videosIri = [...campaign.videos];

    for (let img of images) {
      const imgIri = await uploadImage(img);
      console.log('imgIri', imgIri['@id']);
      imagesIri.push(imgIri['@id']);
    }

    for (let vid of videos) {
      const videoIri = await uploadVideo(vid);
      console.log('video Iri', videoIri['@id']);
      videosIri.push(videoIri['@id']);
    }

    data['images'] = imagesIri;
    data['videos'] = videosIri;
    console.log('data', data);
    try {
      const res = await updateCampaign(id, {
        name:data['name'],
        startTime:data['startTime'],
        endTime:data['endTime'],
        channel: data['channel'],
        message: data['message'],
        videos: data['videos'],
        photos: data['images']
      });
      if (res.status === 200) {
        toast.success('Votre campagne a été configurer avec succès');
        navigate('/campaigns');
      } else {
        toast.error(
          res && res.data && res.data.message
            ? res.data.message
            : 'Erreur inconnu est survenu.'
        );
      }
    } catch (ex) {
      console.log('ex', ex.message);
    }
  };

  return (
    <form ref={uploadFormRef} onSubmit={handleSubmit(onSubmit)} className="container">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-10 sm:px-0 px-4">
        <div>
          <div className="mb-3 text-left">
            <label htmlFor="name">{t('translation.campaignName')}</label>
            <input {...register('name')}
              placeholder="Nom de la campagne"
              className="w-full border-2 border-black bg-white rounded-lg p-3"
            />
          </div>
          <div className="mb-3 text-left">
            <label className="text-black">{t('translation.releaseDate')}</label>
            <input {...register('startTime')}
              type="datetime-local"
              className="w-full border-2 border-black bg-white rounded-lg p-3"
            />
          </div>
          <div className="mb-3 text-left">
            <label className="text-black">{t('translation.releaseDate')}</label>
            <input {...register('endTime')}
              type="datetime-local"
              className="w-full border-2 border-black bg-white rounded-lg p-3"
            />
          </div>
        </div>
        <div>
          <div className="mb-3 text-left">
            <label htmlFor="channel" className="text-black">{t('translation.channelType')}</label>
            <select {...register('channel')}
              name="channel"
              onChange={handleChannelChange}
              className="w-full border-2 border-black bg-white rounded-lg p-3"
            >
              <option value="">{t('translation.selectYourChannel')}</option>
              <option value="whatsapp">Whatsapp</option>
              <option value="sms">SMS</option>
              <option value="email">Email</option>
            </select>
          </div>
          <div className="flex items-start flex-col">
            <label htmlFor="message" className="text-black">{t('translation.message')}</label>
            <textarea
              className="w-full p-3 border-2 border-black rounded-lg normal"
              {...register('message', {maxLength: parseInt(messageMaxLength)})}
              placeholder={t('translation.message')}
            />
            <span
              className="text-red-700 uppercase text-xs font-bold py-3">{errors.message?.type === 'maxLength' && `${t('translation.maxCharactersIs')} ${messageMaxLength}`}</span>
          </div>
          {showUpload &&
              <>
                <ImageDropzone onChange={handleImageChange} />
                <VideoDropzone onChange={handleVideoChange}/>
              </>
          }
        </div>
      </div>
      <button
        className="blue-button float-right inline-block uppercase font-semibold text-xs" type="submit">{t('translation.send')}</button>
    </form>
  );
};
