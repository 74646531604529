import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {sendPriceOffer} from '../../features/offer/offerPriceAPI';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {useTranslation} from 'react-i18next';

export default function PriceOfferDialog({customCSSClasses,buttonLabel,dialogContentText,textFieldLabel,textFieldType,dialogTitle, productIri}) {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [price, setPrice] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendPriceOffer = async () => {
    try {
      const response = await sendPriceOffer(productIri,parseInt(price));
      if(response.status === 201) {
        handleClose();
        toast.success(`${t('translation.offerReceived')}`);
      } else {
        toast.error(response['hydra:error']);
      }
    } catch(ex) {
      toast.error(`${t('translation.anErrorHasOccurred')}`);
    }
  };

  return (
    <>
      <button className={`${customCSSClasses} flex items-center`} onClick={handleClickOpen}>
        <StarBorderIcon /> {buttonLabel}
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContentText}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="price"
            label={textFieldLabel}
            type={textFieldType}
            fullWidth
            variant="standard"
            onChange={(e)=> setPrice(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('translation.close')}</Button>
          <Button onClick={handleSendPriceOffer}>{t('translation.send')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PriceOfferDialog.propTypes = {
  customCSSClasses:PropTypes.string,
  buttonLabel:PropTypes.string.isRequired,
  dialogContentText:PropTypes.string,
  textFieldLabel:PropTypes.string.isRequired,
  textFieldType:PropTypes.string.isRequired,
  dialogTitle:PropTypes.string,
  productIri:PropTypes.string.isRequired,
};
