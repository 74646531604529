import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export default function PerPage({onPerPage, perPages, defaultValue}) {
  const {t} = useTranslation();
  const sortRef = useRef(null);

  useEffect(() => {
    if (!sortRef.current) return;
    onPerPage(sortRef.current.value);
  }, [sortRef.current]);

  return (
    <div className="sort flex flex-row items-center gap-5">
      <label htmlFor="perPage" className="text-custom-blue whitespace-nowrap mr-2 capitalize">{t('translation.itemsPerPage')}</label>
      <select id="perPage" className="max-w-max" onChange={(e) => onPerPage(e.target.value)} ref={sortRef} value={defaultValue ? defaultValue : ''}>
        {perPages && perPages.map(perPage => <option key={perPage} value={perPage}>{perPage}</option>)}
      </select>
    </div>
  );
}

PerPage.propTypes = {
  onPerPage: PropTypes.func,
  perPages: PropTypes.array,
  defaultValue:PropTypes.any
};

PerPage.defaultProps = {
  perPages: [9, 18, 36],
};
