import PropTypes from 'prop-types';
import CreditIcon from '../assets/images/coins-icon.png';
import {useTranslation} from 'react-i18next';

function Credit({credit}) {
  const {t} = useTranslation();
  return (
    <div className="flex items-center gap-2">
      <img src={CreditIcon} alt="coins" />
      <div className="flex flex-column items-start text-white leading-none">
        <span className="text-xs font-semibold">{t('translation.credits')}</span>
        {credit !== null &&
            <span>{credit ? credit.balance : '0'}</span>
        }
      </div>
    </div>
  );
}

export default Credit;

Credit.propTypes = {
  credit: PropTypes.object.isRequired,
};
