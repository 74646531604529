import Header from '../components/common/Header';
import ProductList from '../components/product/productList';
import PropTypes from 'prop-types';

function Products({showUserProductsOnly}) {

  return (
    <div className="relative">
      <Header/>
      <ProductList showUserProductsOnly={showUserProductsOnly} />
    </div>
  );
}

export default Products;

Products.propTypes = {
  showUserProductsOnly: PropTypes.bool,
};
