import instagramIcon from '../../assets/images/instagram-icon.svg';
import facebookIcon from '../../assets/images/facebook-icon.svg';
import youtubeIcon from '../../assets/images/youtube-icon.svg';

function SocialMedia() {
  return (
    <div className="flex flex-row gap-3 items-center">
      <a href="https://www.facebook.com/Ocarzmaroc/" target="_blank" rel="noreferrer">
        <img src={facebookIcon} alt=""/>
      </a>
      <a href="https://www.youtube.com/ocarz" target="_blank" rel="noreferrer">
        <img src={youtubeIcon} alt=""/>
      </a>
      <a href="https://www.instagram.com/ocarzmaroc" target="_blank" rel="noreferrer">
        <img src={instagramIcon} alt=""/>
      </a>
    </div>
  );
}

export default SocialMedia;
