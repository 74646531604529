import PropTypes from 'prop-types';

function TextDivider({text}) {
  return (
    <div className="hr-sect">
      {text}
    </div>
  );
}

export default TextDivider;

TextDivider.propTypes  = {
  text: PropTypes.string
};