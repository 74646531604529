import axios from 'axios';
import {API_BASE_URL} from '../../services/axiosInstance';

export const validateResetPasswordHash = async (hash) => {
  const data = {hash};
  return (await axios({
    method:'POST',
    url: `${API_BASE_URL}/validate_reset_password_hash`,
    data
  }));
};