import Header from '../../components/common/Header';
import {ChannelsForm} from '../../components/ChannelsForm';
import {useTranslation} from 'react-i18next';

function CampaignChannel() {
  const {t} = useTranslation();
  return (
    <>
      <Header/>
      <div className="sm:pl-0 md:ml-10 py-20 bg-white font-montserrat text-center h-screen">
        <h1 className="font-bold text-3xl md:text-2xl sm:text-lg uppercase mb-4">{t('translation.editCampaign')}</h1>
        <ChannelsForm/>
      </div>
    </>
  );
}

export default CampaignChannel;
