import Header from '../../components/common/Header';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMeAsync, selectMe} from '../../features/me/meSlice';
import {fetchCreditAsync, selectCredit} from '../../features/credit/creditSlice';
import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {fetchOrderById} from '../../features/order/orderAPI';
import {API_BASE_URL} from '../../services/axiosInstance';
import {selectToken} from '../../features/auth/authSlice';
import {useTranslation} from 'react-i18next';

function ViewOrder() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const credit = useSelector(selectCredit);
  const [order, setOrder] = useState([]);
  const params = useParams();
  const token = useSelector(selectToken);

  useEffect(() => {
    if (!me) {
      dispatch(fetchMeAsync());
    }
  }, []);

  useEffect(() => {
    if (me && !credit) {
      dispatch(fetchCreditAsync(me.credit));
    }
  }, [me]);

  useEffect(() => {
    const fetchData = async () => {
      setOrder(await fetchOrderById(params.id));
    };
    fetchData();
  }, [params]);

  const onPay = () => {
    window.location.replace(`${API_BASE_URL}/orders/${order.id}/payment_request?bearer=${token}`);
  };

  return (
    <div className="relative">
      <Header/>
      {order ?
        <div className="2xl:container sm:pl-0 md:ml-10 bg-white">
          {order.status === 'unpaid' ?
            <>
              <h1 className="font-montserrat text-black text-center text-2xl uppercase font-semibold px-3 w-full">
                {order.campaign ?
                  `${t('translation.orderOf')} ${order.campaign.totalOrderedProductsCount} ${t('translation.leads')}`
                  :
                  `${t('translation.orderOf')} ${order.credit} ${t('translation.credits')} (1credit = 180dh)`
                }
              </h1>
              <div className="flex justify-center my-8 pb-8">
                <button className="blue-button !px-6" onClick={onPay}>{t('translation.pay')}</button>
              </div>
            </>
            :
            <>
              {order.status === 'paid' &&
                <div className="p-8">
                  <p>{t('translation.OrderPayed')}</p>
                  <Link to="/orders">
                    <button className="blue-button">{t('translation.back')}</button>
                  </Link>
                </div>
              }
            </>
          }
        </div>
        : 'loading...'}
    </div>
  );
}

export default ViewOrder;
