import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const Languages = ['fr', 'en'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator', 'path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'subdomain'],
    },
    fallbackLng: 'en',
    lng: process.env.REACT_APP_DEFAULT_LANG, // default language
    debug: true,
    whitelist: Languages,
    interpolation: {
      escapeValue: false
    }
  });


export default i18n;
