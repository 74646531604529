import Header from '../../components/common/Header';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMeAsync, selectMe} from '../../features/me/meSlice';
import {
  fetchCreditAsync,
  selectCredit,
} from '../../features/credit/creditSlice';
import {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
// import {useNavigate} from 'react-router-dom';
import {formatNumberThreeDigits} from '../../helpers/util';
import {CREDIT_PRICE} from '../../components/common/contants';
import CreditCounter from '../../components/CreditCounter';
import Button from '../../components/common/Button';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {createOrder} from '../../features/order/orderAPI';
import {useTranslation} from 'react-i18next';

const Schema = Yup.object().shape({
  credit: Yup.number().required('Ce champ est obligatoire'),
});

function CreateOrder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const credit = useSelector(selectCredit);
  const [violations, setViolations] = useState([]);
  const [counter, setCounter] = useState(10);
  const {t} = useTranslation();


  useEffect(() => {
    if (!me) {
      dispatch(fetchMeAsync());
    }
  }, []);

  useEffect(() => {
    if (me && !credit) {
      dispatch(fetchCreditAsync(me.credit));
    }
  }, [me]);

  const handleChange = (e) => {
    setCounter(parseInt(e.target.value));
  };


  return (
    <>
      <Header/>
      {credit ? (
        <div className="sm:pl-0 md:ml-10 py-20 bg-white font-montserrat text-center">
          <h1 className="py-5  text-3xl font-semibold uppercase">
            {t('translation.buyOpportunities')}
          </h1>
          <Formik
            initialValues={{credit: 0}}
            validationSchema={Schema}
            onSubmit={ async (values,{setSubmitting}) => {
              try {
                const response = await createOrder(values);
                setSubmitting(false);
                if (response.status === 201) {
                  setSubmitting(false);
                  toast.success(`${t('translation.orderSuccess')}`);
                  setTimeout(() => {
                    navigate(`/orders/${response.data.id}/pay`);
                  }, 3000);
                } else if (
                  response.status === 422 &&
                    response.data.violations
                ) {
                  const violations = {};
                  for (const v of response.data.violations) {
                    violations[v.propertyPath] = v.message;
                  }
                  setViolations(violations);
                } else {
                  toast.error(
                    response && response.data && response.data.message
                      ? response.data.message
                      : 'Erreur inconnu est survenu.'
                  );
                }
              } catch (ex) {
                console.log('ex', ex.message);
              }
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              values
            }) => (
              <form
                className="flex items-center flex-col py-4 "
                onSubmit={handleSubmit}
              >
                <CreditCounter
                  type="number"
                  name="credit"
                  placeholder={t('translation.opportunities')}
                  onChange={handleChange}
                  setCounter={setCounter}
                  onBlur={handleBlur}
                  label={t('translation.opportunities')}
                  counter={counter}
                  value={values.credit = counter}
                />

                {((touched.credit && errors.credit) || violations.credit) && (
                  <span
                    className="bg-white rounded px-2 py-2 mb-2 text-center"
                    style={{color: 'red'}}
                  >
                    {errors.credit ? errors.credit : violations.credit}
                  </span>
                )}

                <p className="uppercase mt-5 py-3 flex gap-x-1">
                  <span>{t('translation.totalToPay')}:</span>
                  <span className="text-[#0028d5] font-bold">
                    {formatNumberThreeDigits(counter * CREDIT_PRICE)} DH
                  </span>
                </p>
                <Button
                  btnClass="btn-rounded text-white uppercase font-semibold bg-[#0028d5]"
                  type="submit"
                  disabled={isSubmitting}
                  label={t('translation.payment')}
                />
              </form>
            )}
          </Formik>
        </div>
      ) : (
        'loading...'
      )}
    </>
  );
}

export default CreateOrder;
