import './index.css';

import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Products from './pages/Products';
import navigationService from './services/NavigationService';
import 'react-toastify/dist/ReactToastify.css';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import ProductDetails from './pages/productDetails';
import Home from './pages/Home';
import ChangePassword from './pages/ChangePassword';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import VerifyEmail from './components/VerifyEmail';
import CreateOrder from './pages/order/CreateOrder';
import Orders from './pages/order/Orders';
import PayOrder from './pages/order/PayOrder';
import UpdateProfile from './components/UpdateProfile';
import Campaigns from './pages/campaign/Campaigns';
import CreateCampaign from './pages/campaign/CreateCampaign';
import ViewOrder from './pages/order/ViewOrder';
import CampaignChannel from './pages/campaign/CampaignChannel';

function App() {
  const navigate = useNavigate();
  const PLATFORM = process.env.REACT_APP_TARGETED_PLATFORM;
  useEffect(() => {
    navigationService.navigate = navigate;
  }, [navigate]);

  useEffect(()=> {
    if(PLATFORM === 'ocarzpro') {
      document.title = 'Ocarz Pro';
    } else if (PLATFORM === 'switchypro') {
      document.title = 'Switchy Pro';
    }
  },[PLATFORM]);

  return (
    <React.StrictMode>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verify-email/:hash" element={<VerifyEmail />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit" element={<UpdateProfile />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:hash" element={<ResetPassword />} />
        <Route path="/my-products" element={<Products showUserProductsOnly={true} />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductDetails />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/create" element={<CreateOrder />} />
        <Route path="/orders/:id" element={<ViewOrder />} />
        <Route path="/orders/:id/pay" element={<PayOrder />} />
        <Route path="/orders/:id/pay/:status" element={<PayOrder />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/campaigns/create" element={<CreateCampaign />} />
        <Route path="/campaigns/config/:id" element={<CampaignChannel />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </React.StrictMode>
  );
}

export default App;
