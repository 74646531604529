import axios from 'axios';

export const verifyEmail = (hash) => {
  const data = {hash};
  return axios({
    method: 'POST',
    // eslint-disable-next-line no-undef
    url: `${process.env.REACT_APP_API_HOST}/verify_email`,
    data
  });
};