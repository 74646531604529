import brands1Img from '../../assets/images/brands-1.png';
import brands2Img from '../../assets/images/brands-2.png';

function CarBrands() {
  return (
    <div className="flex flex-col sm:flex-row bg-white py-5">
      <div className="flex-1 float-right mb-8 sm:mb-0">
        <img src={brands1Img} alt="" className="inline-block float-right"/>
      </div>
      <div className="flex-1">
        <img src={brands2Img} alt=""/>
      </div>
    </div>
  );
}

export default CarBrands;
