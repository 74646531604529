import axiosInstance from '../../services/axiosInstance';

export function fetchOrders(page, perPage, sort) {
  return new Promise((resolve, reject) => {
    axiosInstance.get(`/orders?page=${page}&itemsPerPage=${perPage}${sort ? `&${sort}` : ''}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function fetchOrderById(id) {
  return fetchOrder(`/orders/${id}`);
}

export function fetchOrder(iri) {
  return new Promise((resolve, reject) => {
    axiosInstance.get(iri)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}


export function createOrder(order) {
  return axiosInstance.post('/orders', order);
}
