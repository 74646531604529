import {useEffect, useState} from 'react';
import ImageNotAvailable from '../../assets/images/image-not-available.png';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {fetchLatestProducts} from '../../features/products/productAPI';

function LatestProductsCarousel() {
  const {t} = useTranslation();
  const TARGETED_PLATFORM = process.env.REACT_APP_TARGETED_PLATFORM;
  const [products, setProducts] = useState([]);
  useEffect(async () => {
    const response = await fetchLatestProducts('/products/latest?itemsPerPage=5');
    setProducts(response['hydra:member']);
  }, []);

  return (
    <div className="bg-white">
      <div className="latest-products text-center  py-4 px-0 relative">
        <h1 className="font-axis-bold text-2xl py-3">{t(`${TARGETED_PLATFORM}.latestCarsTitle`)}</h1>
        <Link to="/login">
          <button className="lv-nav-buttons-4 sm:lv-nav-buttons-6 lg:lv-nav-buttons-8 prev">{t('translation.previous')}</button>
        </Link>
        <div className="grid items-center justify-items-center grid-cols-1 sm:grid-cols-4 lg:grid-cols-5 xl:mx-36">
          {products.length > 0 && products.map((product, index) =>
            <div className="p-4 max-w-xs col-span-5 sm:col-span-2 lg:col-span-1" key={index}>
              <Link to="/login">
                <div className="bg-white">
                  <img style={{maxHeight: 173, minHeight: 173}} className="object-contain bg-white mx-auto"
                    src={`${(product.images && product.images.length) ? product.images[0]['contentUrl'] : `${ImageNotAvailable}`}`}
                    alt=""/>
                </div>
                <div className="bg-white py-2 px-4">
                  <p className="font-futura-light text-center">{product.title} </p>
                </div>
              </Link>
            </div>
          )}
        </div>
        <Link to="/login">
          <button className="lv-nav-buttons-4 sm:lv-nav-buttons-6 lg:lv-nav-buttons-8 next">{t('translation.next')}</button>
        </Link>
      </div>
    </div>
  );
}

export default LatestProductsCarousel;
