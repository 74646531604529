import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import pandaMascot from './../../assets/images/panda.svg';
import ocarzPro from './../../assets/images/ocarz-logo.svg';
import switchypro from './../../assets/images/switchy.png';
import useIsConnected from '../../hooks/useIsConnected';
import LanguageSwitcher from '../LanguageSwitcher';


function Navbar() {
  const {t} = useTranslation();
  const isConnected = useIsConnected();
  // eslint-disable-next-line no-undef
  const TARGETED_PLATFORM = process.env.REACT_APP_TARGETED_PLATFORM.toLowerCase();
  const OCARZPRO = process.env.REACT_APP_TARGETED_PLATFORM.toLowerCase() === 'ocarzpro';
  const SWITCHYPRO = process.env.REACT_APP_TARGETED_PLATFORM.toLowerCase() === 'switchypro';

  return (
    <nav
      className="relative container mx-auto p-4 pb-0 flex sm:flex-row flex-col items-center justify-between gap-4 font-montserrat tex-sm font-semibold">
      <ul className="flex items-baseline gap-x-10">
        <li className="pb-5">
          <img className="sm:w-auto max-w-[200px]" src={OCARZPRO && ocarzPro || SWITCHYPRO && switchypro} alt=""/>
        </li>
        {OCARZPRO &&
        <li>
          <img className="absolute left-0 sm:right-0 sm:mr-[13rem] sm:left-auto ml-0 mx-[50px] -bottom-[10px]" src={pandaMascot} alt=""/>
        </li>
        }
        
      </ul>
      <ul className="hidden xl:flex xl:flex-row items-center justify-center gap-6 text-white uppercase ">
        <li><Link to="/products">{t(`${TARGETED_PLATFORM}.headerMainTitle`)}</Link></li>
      </ul>
      <ul className="flex laptop:flex-row items-center justify-center gap-4 text-primary-yellow uppercase">
        <li>
          {!isConnected && <Link to="/login">{t('translation.login')}</Link>}
        </li>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
