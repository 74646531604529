import loginFormBg from '../../assets/images/bg-form.png';
import ocarzProLogo from '../../assets/images/ocarz-pro.svg';
import {useTranslation} from 'react-i18next';

function ResetPasswordExpired() {
  const {t} = useTranslation();
  return (
    <div className="md:col-span-5 lg:col-span-4 m-0 flex items-center sm:justify-center justify-start flex-col md:py-18 h-screen" style={{background: `url(${loginFormBg}) no-repeat center center/cover` }}>
      <img src={ocarzProLogo} className="mx-auto lg:mb-22 my-24" alt=""/>
      <p className="uppercase text-white text-2xl font-semibold">{t('translation.expiredLink')}</p>
    </div>
  );
}

export default ResetPasswordExpired;
