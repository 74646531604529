import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCredit } from './creditAPI';

const initialState = {
  value: null,
  status: 'idle',
};

export const selectCredit = (state) => state.credit.value;

export const fetchCreditAsync = createAsyncThunk(
  'credit/fetchCredit',
  async (iri) => {
    return await fetchCredit(iri);
  }
);

export const creditSlice = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    destroyCredit: (state) => {
      state.value = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCreditAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      });
  },
});

export default creditSlice.reducer;
