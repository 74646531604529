import axiosInstance from '../../services/axiosInstance';

export function fetchCampaigns(page, perPage, sort, query = '') {
  return new Promise((resolve, reject) => {
    axiosInstance.get(`/campaigns?page=${page}&itemsPerPage=${perPage}${sort ? `&${sort}&${query}` : ''}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function fetchCampaignById(id) {
  return fetchCampaign(`/campaigns/${id}`);
}

export function fetchCampaign(iri) {
  return new Promise((resolve, reject) => {
    axiosInstance.get(iri)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}


export function createCampaign(campaign) {
  return axiosInstance.post('/campaigns', campaign);
}

export function updateCampaign(id, {name,startTime,endTime,channel, message,videos, photos}) {
  return axiosInstance.put(`/campaigns/${id}`,{
    name:name,
    startTime:startTime,
    endTime:endTime,
    channel: channel,
    message: message,
    videos:videos,
    photos: photos,
  });
}
