import {useDropzone} from 'react-dropzone';
import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import {useTranslation} from 'react-i18next';

// eslint-disable-next-line react/prop-types
function VideoDropzone({onChange}) {
  const {t} = useTranslation();
  const [paths, setPaths] = useState([]);
  const [files, setFiles] = useState([]);

  const maxSize = 16777216;
  function maxSizeValidator(file) {
    if (file.size > maxSize) {
      return {
        code: 'file-too-large',
        message: `${t('translation.fileMaxSize')} ${maxSize} `
      };
    }
    return null;
  }

  useEffect(() => {
    onChange(files);
  },[files]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
    setPaths(acceptedFiles.map(file => URL.createObjectURL(file)));
  }, [setPaths,setFiles]);

  const {getRootProps, getInputProps,fileRejections} = useDropzone({
    onDrop,
    multiple:true,
    accept:{
      'video/*': ''
    },
    validator: maxSizeValidator
  });

  // eslint-disable-next-line no-unused-vars
  const handleRemoveFile = (e, index) => {
    e.persist();
    let removedFiles = [...files];
    let removedPaths = [...paths];
    removedFiles.splice(index,1);
    removedPaths.splice(index,1);
    setFiles(removedFiles);
    setPaths(removedPaths);
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <ul>
        {errors.map(e => (
          <li key={e.code} className="text-red-700 text-xs uppercase font-bold my-3">{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <>
      <div className="outline-dashed outline-2 outline-offset-2 p-6 rounded-lg bg-gray-300 flex justify-center items-center cursor-pointer">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <p>{t('translation.dropVideosHere')}.</p>
        </div>
      </div>
      <aside>
        <ul>{fileRejectionItems}</ul>
      </aside>
      <div className="flex items-start gap-3 my-3">
        {paths.map((path, index) =>
          <div key={index}>
            <button
              className="relative"
              onClick={(e) => handleRemoveFile(e, index)}
            >
              <DoDisturbOnOutlinedIcon/>
            </button>
            <video className='rounded-lg' key={path} width="300" height="300" controls >
              <source src={path} type="video/mp4"/>
            </video>
          </div>
        )
        }
      </div>

    </>
  );
}

export default VideoDropzone;

VideoDropzone.propsType = {
  onChange: PropTypes.func.isRequired
};
