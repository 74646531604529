import Newsletter from './Newsletter';
import Copyright from './Copyright';

function Footer() {
  return (
    <>
      <footer className="custom-footer-v1 py-10 px-4 sm:px-8 lg:px-24 xl:px-32 px-4">
        <div className="my-12 border-b-2 border-blue-700"/>
        <Newsletter />
        <Copyright />
      </footer>
    </>
  );
}

export default Footer;
