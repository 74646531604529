import NewsletterForm from './NewsletterForm';
import SocialMedia from './homepage/SocialMedia';
import {useTranslation} from 'react-i18next';

function Newsletter() {
  const {t} = useTranslation();
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-center items-start">
        <div className="font-axis-bold">
          <h3 className="text-sm text-custom-blue mb-2">{t('translation.joinUs')}</h3>
          <p className="text-white text-xs">{t('translation.subscribeToNewsletter')}</p>
        </div>
        <NewsletterForm/>
        <SocialMedia />
      </div>
    </>
  );
}

export default Newsletter;
