import Navbar from '../components/common/Navbar';
import HeroSection from '../components/homepage/HeroSection';
import LatestProductsCarousel from '../components/homepage/LatestProductsCarousel';
import CarBrands from '../components/homepage/CarBrands';
import MediaSection from '../components/homepage/MediaSection';
import Footer from '../components/Footer';
import BrandsBanner from '../components/BrandsBanner';

function Home() {
  const OCARZPRO_PLATFORM =  process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro';
  const SWITCHYPRO_PLATFORM =  process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro';
    
  return (
    <>
      <div className="xl:ml-32 sm:ml-10">
        <Navbar />
        <HeroSection />
        <LatestProductsCarousel />
        {OCARZPRO_PLATFORM && <CarBrands />}
        {SWITCHYPRO_PLATFORM && <BrandsBanner />}
        <MediaSection/>
      </div>
      <Footer />
    </>
  );
}

export default Home;
