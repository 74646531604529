import PropTypes from 'prop-types';
import {formatNumberThreeDigits} from '../../helpers/util';
import ShowProductOwnerInfoDialog from './ShowProductOwnerInfoDialog';
import TextDivider from '../TextDivider';
import PriceOfferDialog from './PriceOfferDialog';
import {useTranslation} from 'react-i18next';

function ProductCardDetails({title, price, productIri, owner, onContact, flexBasis}) {
  const handleOnContact = (person) => {
    onContact(person);
  };

  const {t} = useTranslation();

  return (
    <div className={`border-[3px] border-[#dcdcdc] relative ${flexBasis}`}>
      <div className="text-black">
        <div className="ad-card-body py-8 text-center">
          <div className="ad-card-title">
            <h2 className="font-montserrat font-bold text-2xl">{title}</h2>
          </div>
          <div className="price-details py-3">
            <TextDivider text={t('translation.price')} />
            <p className="price border-b-2 border-[#d3d5d6] pb-4 text-4xl" >{formatNumberThreeDigits(price)} dhs</p>
          </div>
          {owner &&
          <div className="price-details py-3">
            <p>Téléphone Mobile du propriétaire</p>
            <p className="price">{owner.phone}</p>
          </div>}
        </div>
      </div>
      <div className="flex items-center flex-col justify-center my-4 w-auto lg:left-1 lg:right-1 mx-6">
        {!owner &&
        <ShowProductOwnerInfoDialog productIri={productIri} customCSSClasses="test-drive-btn" onContact={handleOnContact}/>}
        {/*<InterestedByProductDialog productIri={productIri} customCSSClasses='test-drive-btn' />*/}
        <PriceOfferDialog productIri={productIri} customCSSClasses='blue-light-btn' buttonLabel={t('translation.makeAnOffer')} dialogContentText={t('translation.makeAnOffer')} textFieldLabel={t('translation.price')} textFieldType='number' dialogTitle=''/>
      </div>
    </div>
  );
}

ProductCardDetails.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  productIri: PropTypes.string.isRequired,
  owner: PropTypes.object,
  onContact: PropTypes.func.isRequired,
  flexBasis: PropTypes.string
};

export default ProductCardDetails;
