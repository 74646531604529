import {Formik} from 'formik';
import {Link, useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {setToken} from '../../features/auth/authSlice';
import {authenticate} from '../../features/auth/authAPI';
import {useTranslation} from 'react-i18next';

function Form() {
  const {t} = useTranslation();
  let navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const platform = process.env.REACT_APP_TARGETED_PLATFORM;
  return (
    <Formik
      initialValues={{username: '', password: ''}}
      validate={values => {
        const errors = {};
        if (!values.username) {
          errors.username = `${t('translation.requiredField')}`;
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
        ) {
          errors.username = `${t('translation.invalidEmail')}`;
        } else if (!values.password) {
          errors.password = `${t('translation.requiredField')}`;
        }
        return errors;
      }}
      onSubmit={async (values, {setSubmitting}) => {
        try {
          const res = await authenticate(values);
          if (res.status === 200) {
            localStorage.setItem('token', res.data.token);
            dispatch(setToken(res.data.token));
            setSubmitting(false);
            navigate('/products');
          } else {
            setMessage(`${t('translation.IncorrectEmailOrPassword')}`);
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 401) {
            const responseError = ex.response.data.message;
            if (responseError === 'Invalid credentials.') {
              setMessage(`${t('translation.IncorrectEmailOrPassword')}`);
            } else {
              setMessage(responseError);
            }
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form className="flex items-center flex-col text-white py-4 " onSubmit={handleSubmit}>
          <h1 className="font-axis-bold">{t('translation.login')}</h1>
          <div className="flex items-center flex-col">
            <input
              type="email"
              name="username"
              placeholder={t('translation.email')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              className={`${errors.username && 'border-2 border-red-600'} bg-white p-3 rounded my-3 text-gray-500`}
            />
            {touched.username && errors.username &&
                            <small className="text-red-600 font-semibold bg-white rounded py-1 px-4">
                              {errors.username}
                            </small>}
            <input
              type="password"
              name="password"
              placeholder="mot de passe"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={`${errors.password && 'border-2 border-red-600'} bg-white p-3 rounded my-3 text-gray-500`}
            />
            {touched.password && errors.password &&
                            <small className="text-red-600 font-semibold bg-white rounded py-1 px-4">
                              {errors.password}
                            </small>}
          </div>
          {message &&
                        <small className="text-red-600 font-semibold bg-white rounded py-1 px-4 mx-4"> {message}</small>
          }
          <div className="my-5 font-axis text-xs">
            <Link to="/forget-password">{t('translation.forgetPassword')}</Link>
          </div>
          <button className="rounded text-white p-3 px-6 bg-black" type="submit" disabled={isSubmitting}>
            {t('translation.login')}
          </button>
          <div className="my-8 font-axis text-center text-xs">
            <p className="mb-4">
              {t(`${platform}.signUpHint`)}
            </p>
            <Link to="/signup">
              <button className="rounded text-white p-3 px-6 bg-blue-600">
                {t('translation.createAccount')}
              </button>
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default Form;
