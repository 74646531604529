import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { updateProfile } from '../../features/profile/profileAPI';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  fetchProfileAsync,
  selectProfile,
} from '../../features/profile/profileSlice';
import { fetchMeAsync } from '../../features/me/meSlice';
import {useTranslation} from 'react-i18next';



function ProfileForm() {
  const dispatch = useDispatch();
  const me = useSelector(selectProfile);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const schema = Yup.object().shape({
    gender: Yup.string(),
    firstName: Yup.string().matches(/^[A-Za-z]/, `${t('translation.charOnly')}`),
    lastName: Yup.string().matches(/^[A-Za-z]/, `${t('translation.charOnly')}`),
    phone: Yup.string().matches(/^(0)([0-9]){9}$/i, `${t('translation.phoneInvalid')}`),
    type: Yup.string(),
    company: Yup.string(),
  });
    
  useEffect(() => {
    if (!me) {
      dispatch(fetchProfileAsync());
    }
  }, []);

  return (
    <Box sx={{ maxWidth: '50%', mx: 'auto', textAlign: 'center' }}>
      {me && (
        <Formik
          initialValues={{
            gender: me.gender,
            firstName: me.firstName,
            lastName: me.lastName,
            phone: me.phone,
            type: me.type,
            company: me.company,
          }}
          validationSchema={schema}
          onSubmit={async (values) => {
            updateProfile(values)
              .then((res) => {
                if (res.status === 200) {
                  dispatch(fetchProfileAsync());
                  dispatch(fetchMeAsync());
                  toast.success(`${t('translation.yourProfileHasBeenUpdated')}`);
                  setTimeout(() => {
                    navigate('/profile');
                  }, 1000);
                } else {
                  toast.error(`${t('translation.unknownErrorHasOccurred')}`);
                }
              })
              .catch(() => {
                toast.error(`${t('translation.unknownErrorHasOccurred')}`);
              });
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
          }) => {
            return (
              <div className="font-montserrat">
                <h1 className="text-2xl font-bold text-center text-black py-10">
                  {t('translation.editProfile')}
                </h1>
                <form
                  className="flex items-center flex-col relative text-white"
                  onSubmit={handleSubmit}
                >
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-3 w-full">
                    <div>
                      <select
                        name="gender"
                        id="gender"
                        className={`${
                          errors.gender &&
                          touched.gender &&
                          errors.gender &&
                          'border border-red-600'
                        } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gender}
                      >
                        <option value="">{t('translation.gender')}</option>
                        <option value="man">{t('translation.man')}</option>
                        <option value="woman">{t('translation.woman')}</option>
                      </select>
                      <span className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600">
                        <ErrorMessage name="gender" />
                      </span>
                    </div>
                    <div />
                  </div>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-3 w-full">
                    <div className="flex items-center flex-col relative">
                      <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder={t('translation.lastName')}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${
                          errors.lastName &&
                          touched.lastName &&
                          errors.lastName &&
                          'border border-red-600'
                        } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                      />
                      <span className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600">
                        <ErrorMessage name="lastName" />
                      </span>
                    </div>
                    <div className="flex items-center flex-col relative">
                      <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder={t('translation.firstName')}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${
                          errors.firstName &&
                          touched.firstName &&
                          errors.firstName &&
                          'border border-red-600'
                        } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                      />
                      <span className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600">
                        <ErrorMessage name="firstName" />
                      </span>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-3 w-full">
                    <div className="flex items-center flex-col relative">
                      <input
                        id="phone"
                        name="phone"
                        type="text"
                        placeholder={t('translation.phoneNumber')}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${
                          errors.phone &&
                          touched.phone &&
                          errors.phone &&
                          'border border-red-600'
                        } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                      />
                      <span className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600">
                        <ErrorMessage name="phone" />
                      </span>
                    </div>
                    <div className="flex items-center flex-col relative">
                      <select
                        id="type"
                        name="type"
                        className={`${
                          errors.type &&
                          touched.type &&
                          errors.type &&
                          'border border-red-600'
                        } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 font-semibold w-full`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.type}
                      >
                        <option value="">{t('translation.sector')}</option>
                        <option value="Marque automobile">
                          {t('translation.carBrand')}
                        </option>
                        <option value="concessionaire">{t('translation.dealer')}</option>
                        <option value="garage automobile">
                          {t('translation.carWorkshop')}
                        </option>
                        <option value="professionel independant">
                          {t('translation.independentProfessional')}
                        </option>
                      </select>
                      <span className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600">
                        <ErrorMessage name="type" />
                      </span>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-2 w-full">
                    <div className="flex items-center flex-col relative">
                      <input
                        id="company"
                        name="company"
                        type="text"
                        placeholder={t('translation.entreprise')}
                        value={values.company}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${
                          errors.company &&
                          touched.company &&
                          errors.company &&
                          'border border-red-600'
                        } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                      />
                      <span className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600">
                        <ErrorMessage name="company" />
                      </span>
                    </div>
                  </div>
                  <button
                    className="btn-form font-bold uppercase"
                    type="submit"
                  >
                    {t('translation.updateProfile')}
                  </button>
                </form>
              </div>
            );
          }}
        </Formik>
      )}
      <ToastContainer />
    </Box>
  );
}

export default ProfileForm;
