import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {Component} from 'react';
import videoUnavailable from '../../assets/images/video-not-availble.png';
import imageUnavailable from '../../assets/images/image-not-available.png';

function youtube_parser(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  maxHeight: 338,
};

class HeroSlider extends Component {
  state = {
    media: 'gallery'
  };

  render() {
    const {images, videoUrl, flexBasis} = this.props;
    const {media} = this.state;

    return (
      <>
        {media === 'gallery' ?
          <div className={`${flexBasis} relative mb-5`}>
            <span style={{position: 'absolute',top:'50%',left:'.7rem',transform: 'translate(-50%, -50%)',writingMode: 'vertical-rl', zIndex:'10', fontWeight:'light', fontSize:'12px', color:'white', whiteSpace:'nowrap'}}>*Photos non contractuelles</span>
            <Slider {...settings}>
              {images && images.length ? (images.map(src =>
                <div className="w-full max-h-[32rem] text-center relative aspect-[16/9]" key={src}>
                  <div className="lr-blur-bg" style={{ backgroundImage: `url(${src})`}}/>
                  <img className="block w-full h-full object-cover px-10 z-[2]" src={src} alt=""/>
                </div>
              )) : (
                <div className="max-h-[24rem] h-[24rem] w-full text-center bg-gray-300 relative flex justify-center">
                  <div className="flex flex-col items-center absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                    <img className="w-12 my-2 " src={imageUnavailable} alt="image non disponible"/>
                    <p>Image non disponible</p>
                  </div>
                </div>)}
            </Slider>
          </div>
          :
          (
            videoUrl ? <iframe className="max-h-96 h-96 w-full py-4"
              src={`https://www.youtube.com/embed/${youtube_parser(videoUrl)}`}
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen/> : (
              <div className="max-h-96 h-96 w-full text-center bg-gray-300 mb-4 relative">
                <div
                  className="flex flex-col items-center absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 ">
                  <img className="w-12 my-2 " src={videoUnavailable} alt="video unavailable"/><h3>No video</h3></div>
              </div>)
          )}
      </>
    );
  }
}

export default HeroSlider;

HeroSlider.propTypes = {
  images: PropTypes.array.isRequired,
  videoUrl: PropTypes.string,
  flexBasis: PropTypes.string,
};
