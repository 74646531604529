import {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Sort from '../../components/order/sort';
import {fetchOrders} from '../../features/order/orderAPI';
import {useSelector} from 'react-redux';
import {selectToken} from '../../features/auth/authSlice';
import {API_BASE_URL} from '../../services/axiosInstance';
import Header from '../../components/common/Header';
import PerPage from '../../components/common/PerPage';
import {useTranslation} from 'react-i18next';

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(null);
  const [countOrders, setCountOrders] = useState(0);
  const [sort, setSort] = useState(null);
  const token = useSelector(selectToken);

  useEffect(
    () => {
      async function fetchData() {
        if (!page || !perPage || !sort) {
          return;
        }
        const response = await fetchOrders(page, perPage, sort);
        setOrders(response['hydra:member']);
        setPageCount(Math.ceil(response['hydra:totalItems'] / perPage));
        setCountOrders(response['hydra:totalItems']);
      }

      fetchData();
    },
    [page, sort, perPage],
  );

  /*const onOrderViewDetail = (order) => {
    navigate(`/orders/${encodeURIComponent(order['@id'])}`);
  };*/

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const onPay = (order) => {
    window.location.replace(`${API_BASE_URL}/orders/${order.id}/payment_request?bearer=${token}`);
  };

  const onSort = (sort) => {
    setSort(sort);
  };

  const onPerPage = (perPage) => {
    setPage(1);
    setPerPage(perPage);
  };

  const {t} = useTranslation();

  return (
    <div className="2xl:container sm:pl-0 md:ml-10">
      <Header/>
      <div className="bg-white mx-auto relative min-h-screen w-full">
        <div className="px-5">
          <div className="flex flex-col sm:flex-row justify-between sm:items-center">
            <div>
              <div className="flex items-center flex-start gap-4">
                <h1
                  className="uppercase text-blue-800 font-axis-bold text-3xl my-7"> {countOrders ? `${countOrders} ${t('translation.orders')}` : t('translation.NoOrdersAvailable')} </h1>
              </div>
            </div>
            <Sort onSort={onSort}/>
          </div>
          <div className="flex flex-row-reverse my-3 justify-center md:justify-end">
            <Link to="/orders/create" className="green-button text:sm">
              <button>{t('translation.orderOpportunities')}</button>
            </Link>
          </div>
          {orders &&
              <div className="overflow-x-auto relative my-4">
                <table className="bcampaign-collapse w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th className="table-heading">{t('translation.credits')}</th>
                      <th className="table-heading">{t('translation.date')}</th>
                      <th className="table-heading">{t('translation.status')}</th>
                      <th className="table-heading"/>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map(order =>
                      <tr key={order.id}>
                        <td className="table-cell">{order.credit}</td>
                        <td className="table-cell">{(new Date(order.createdAt)).toLocaleString('fr-FR')}</td>
                        <td className="table-cell">{order.status === 'unpaid' ? <span className="bg-orange-600 text-white p-2 rounded-lg whitespace-nowrap">{t('translation.OutstandingPayment')}</span> : <span className="bg-green-600 text-white p-2 rounded-lg">Payé</span>}</td>
                        <td className="table-cell">
                          {order.status === 'unpaid' &&
                      <button onClick={() => onPay(order)} className="blue-button mb-4">{t('translation.pay')}</button>} &nbsp;
                          <Link to={`/orders/${order.id}`}><a className="green-button">{t('translation.preview')}</a></Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>}
          <div className="flex justify-center justify-between xl:my-10 lg:my-14 xl:py-2 lg:py-2 max-w-[100%]">
            <PerPage onPerPage={onPerPage} perPages={[10, 20, 30, 50]} />&nbsp;
            {pageCount > 1 && (
              <ReactPaginate className="pagination"
                breakLabel="..."
                nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Orders.propTypes = {
  showUserProductsOnly: PropTypes.bool,
};
