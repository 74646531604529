import {SignUpForm} from './SignUpForm';
import {Link} from 'react-router-dom';
import ocarzProLogo from '../assets/images/ocarz-pro.svg';
import switchyproLogo from '../assets/images/switchy.png';

function SignUpContainer() {
  const OCARZPRO = process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro';
  const SWITCHYPRO =  process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro';

  return (
    <div className="mx-auto px-4"
      style={{background: 'url(./bg-form.png) no-repeat center center/cover' }}>
      <div className="lg:mb-22 my-24">
        <Link to="/">
          <img src={SWITCHYPRO && switchyproLogo || OCARZPRO && ocarzProLogo} className="mx-auto max-w-[200px]" alt=""/>
        </Link>
      </div>
      <SignUpForm/>
    </div>
  );
}

export default SignUpContainer;
