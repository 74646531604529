import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSlidersH, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {getCollection} from '../../features/common/commonAPI';
import {useTranslation} from 'react-i18next';

export default function ProductFilter({onFilter, isClosed, onToggle, defaultValues}) {
  const { t, i18n } = useTranslation();


  const [brands, setBrands] = useState([{'@id': -1, name: t('translation.selectBrand')}]);
  const [models, setModels] = useState([{'@id': -1, name: t('translation.selectModel')}]);
  const [categories, setCategories] = useState([{'@id': -1, name: t('translation.selectCategory')}]);
  const [cities, setCities] = useState([{'@id': -1, name: t('translation.selectCity')}]);
  const [selectedBrand, setSelectedBrand] = useState(defaultValues && defaultValues.brand ? defaultValues.brand : -1);
  const [selectedStorageCapacity, setSelectedStorageCapacity] = useState(defaultValues && defaultValues.selectedStorageCapacity ? defaultValues.selectedStorageCapacity : -1);
  // const [selectedBatteryStatus, setSelectedBatteryStatus] = useState(defaultValues && defaultValues.batteryStatus ? defaultValues.batteryStatus : -1);
  const [selectedColor, setSelectedColor] = useState(defaultValues && defaultValues.color ? defaultValues.color : -1);
  const [selectedModel, setSelectedModel] = useState(defaultValues && defaultValues.model ? defaultValues.model : -1);
  const [selectedCity, setSelectedCity] = useState(defaultValues && defaultValues.city ? defaultValues.city : -1);
  const [selectedTransmission, setSelectedTransmission] = useState(defaultValues && defaultValues.transmission ? defaultValues.transmission : -1);
  const [selectedFuel, setSelectedFuel] = useState(defaultValues && defaultValues.fuel ? defaultValues.fuel : -1);
  const [selectedMaxYear, setSelectedMaxYear] = useState(defaultValues && defaultValues.maxYear ? defaultValues.maxYear : null);
  const [selectedMinYear, setSelectedMinYear] = useState(defaultValues && defaultValues.minYear ? defaultValues.minYear : null);
  const [selectedMaxMileage, setSelectedMaxMileage] = useState(defaultValues && defaultValues.maxMileage ? defaultValues.maxMileage : null);
  const [selectedMinMileage, setSelectedMinMileage] = useState(defaultValues && defaultValues.minMileage ? defaultValues.minMileage : null);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(defaultValues && defaultValues['price[lte]'] ? defaultValues['price[lte]'] : null);
  const [selectedMinPrice, setSelectedMinPrice] = useState(defaultValues && defaultValues['price[gte]'] ? defaultValues['price[gte]'] : null);
  const [selectedCategory, setSelectedCategory] = useState(defaultValues && defaultValues.category ? defaultValues.category : -1);
  const [filters, setFilters] = useState(defaultValues ? defaultValues : {});

  useEffect(() => {
    const fetchData = async () => {
      const brands = await getCollection('/brands?itemsPerPage=1000');
      const categories = await getCollection('/categories?itemsPerPage=1000');
      const cities = await getCollection('/cities?itemsPerPage=1000');
      brands.unshift({'@id': -1, name:t('translation.selectBrand')});
      categories.unshift({'@id': -1, name:t('translation.selectCategory')});
      cities.unshift({'@id': -1, name: t('translation.selectCity')});

      setBrands(brands);
      setCategories(categories);
      setCities(cities);
    };
    fetchData();
  },[]);

  useEffect(() => {
    if (selectedBrand === null) {
      return;
    }
    const fetchData = async () => {
      const models = await getCollection(`/models?brand=${selectedBrand}&itemsPerPage=1000`);
      models.unshift({'@id': -1, name: t('translation.selectModel')});
      setModels(models);
    };
    fetchData();
  }, [selectedBrand]);

  useEffect(() => {
    const tempBrands = [...brands];
    const tempModels = [...models];
    const tempCategories = [...categories];
    const tempCities = [...cities];
    tempBrands[0].name = t('translation.selectBrand');
    tempModels[0].name = t('translation.selectModel');
    tempCategories[0].name = t('translation.selectCategory');
    tempCities[0].name = t('translation.selectCity');
    setBrands(tempBrands);
    setModels(tempModels);
    setCategories(tempCategories);
    setCities(tempCities);
  }, [i18n.language]);

  const onBrandChange = (e) => {
    const brand = e.target.value;
    setSelectedBrand(brand);
    setSelectedModel(-1);
    if (brand != -1) {
      setFilters({...filters, brand});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.brand;
    setFilters({...tmpFilters});
  };

  const onTransmissionChange = (e) => {
    const transmission = e.target.value;
    setSelectedTransmission(transmission);
    if (transmission != -1) {
      setFilters({...filters, transmission});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.transmission;
    setFilters({...tmpFilters});
  };

  const onStorageCapacityChange = (e) => {
    const storageCapacity = e.target.value;
    setSelectedStorageCapacity(storageCapacity);
    if (storageCapacity != -1) {
      setFilters({...filters, storageCapacity});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.storageCapacity;
    setFilters({...tmpFilters});
  };

  const onModelChange = (e) => {
    const model = e.target.value;
    setSelectedModel(model);
    if (model != -1) {
      setFilters({...filters, model});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.model;
    setFilters({...tmpFilters});
  };

  const onMinMileageChange = (e) => {
    const minMileage = e.target.value;
    setSelectedMinMileage(parseInt(minMileage));
    handleMinMaxFilter({name: 'mileage[gte]', value: parseInt(minMileage)}, {
      name: 'mileage[lte]',
      value: parseInt(selectedMaxMileage)
    });
  };

  const onMaxMileageChange = (e) => {
    const maxMileage = e.target.value;
    setSelectedMaxMileage(parseInt(maxMileage));
    handleMinMaxFilter({name: 'mileage[gte]', value: parseInt(selectedMinMileage)}, {
      name: 'mileage[lte]',
      value: parseInt(maxMileage)
    });
  };

  const onCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    if (category != -1) {
      console.log('category', category);
      setFilters({...filters, category});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.category;
    console.log('tmpFilters', tmpFilters);
    setFilters({...tmpFilters});
  };

  const onFuelChange = (e) => {
    const fuel = e.target.value;
    setSelectedFuel(fuel);
    if (fuel != -1) {
      setFilters({...filters, fuel});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.fuel;
    setFilters({...tmpFilters});
  };

  const onMaxPriceChange = (e) => {
    const maxPrice = e.target.value;
    setSelectedMaxPrice(parseFloat(maxPrice));
    handleMinMaxFilter({name: 'price[gte]', value: parseFloat(selectedMinPrice)}, {name: 'price[lte]', value: parseFloat(maxPrice)});
  };

  const onMinPriceChange = (e) => {
    const minPrice = e.target.value;
    setSelectedMinPrice(parseFloat(minPrice));
    handleMinMaxFilter({name: 'price[gte]', value: parseFloat(minPrice)}, {name: 'price[lte]', value: parseFloat(selectedMaxPrice)});
  };

  const handleMinMaxFilter = ({name: minName, value: min}, {name: maxName, value: max}, minLength = -1) => {
    if (!min && !max) {
      const tmpFilters = {...filters};
      delete tmpFilters[maxName];
      delete tmpFilters[minName];
      setFilters(tmpFilters);
      return;
    }
    if (!min && max) {
      const tmpFilters = {...filters, [maxName]: max};
      delete tmpFilters[minName];
      if (minLength > 0 && minLength > max.toString().length) {
        delete tmpFilters[maxName];
      }
      setFilters(tmpFilters);
      return;
    }
    if (min && !max) {
      const tmpFilters = {...filters, [minName]: min};
      delete tmpFilters[maxName];
      if (minLength > 0 && minLength > min.toString().length) {
        delete tmpFilters[minName];
      }
      setFilters(tmpFilters);
      return;
    }
    if (min && max) {
      const tmpFilters = {...filters};
      if (minLength > 0 && minLength > min.toString().length) {
        delete tmpFilters[minName];
      } else {
        tmpFilters[minName] = min;
      }
      if (minLength > 0 && minLength > max.toString().length) {
        delete tmpFilters[maxName];
      } else {
        tmpFilters[maxName] = max;
      }
      if (parseFloat(min) > parseFloat(max)) {
        delete tmpFilters[maxName];
        delete tmpFilters[minName];
      }
      setFilters(tmpFilters);
    }
  };

  const onMaxYearChange = (e) => {
    const maxYear = e.target.value;
    if (maxYear.length > 4) return;
    setSelectedMaxYear(maxYear);
    handleMinMaxFilter({name: 'year[gte]', value: parseInt(selectedMinYear)}, {name: 'year[lte]', value: parseInt(maxYear)}, 4);
  };

  const onMinYearChange = (e) => {
    const minYear = e.target.value;
    if (minYear.length > 4) return;
    setSelectedMinYear(minYear);
    handleMinMaxFilter({name: 'year[gte]', value: parseInt(minYear)}, {name: 'year[lte]', value: parseInt(selectedMaxYear)}, 4);
  };

  const onCityChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
    if (city != -1) {
      setFilters({...filters, city});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.city;
    setFilters({...tmpFilters});
  };

  // const onBatteryStatus = (e) => {
  //   const batteryStatus = e.target.value;
  //   setSelectedBatteryStatus(batteryStatus);
  //   if (batteryStatus != -1) {
  //     setFilters({...filters, batteryStatus});
  //     return;
  //   }
  //   const tmpFilters = filters;
  //   delete tmpFilters.batteryStatus;
  //   setFilters({...tmpFilters});
  // };

  const onColorChange = (e) => {
    const color = e.target.value;
    setSelectedColor(color);
    if (color != -1) {
      setFilters({...filters, color});
      return;
    }
    const tmpFilters = filters;
    delete tmpFilters.color;
    setFilters({...tmpFilters});
  };

  useEffect(() => {
    onFilter(filters);
  }, [filters]);


  const resetFilter = () => {
    setFilters({});
    setModels([{'@id': -1, name: t('translation.selectModel')}]);
    setSelectedTransmission(null);
    setSelectedFuel(null);
    setSelectedMaxYear(null);
    setSelectedMinYear(null);
    setSelectedMaxMileage(null);
    setSelectedMinMileage(null);
    setSelectedMaxPrice(null);
    setSelectedMinPrice(null);
    setSelectedCategory(null);
    setSelectedBrand(null);
    setSelectedModel(null);
    setSelectedCity(null);
    setSelectedColor(null);
    // setSelectedBatteryStatus(null);
  };


  return (
    <>
      <div className={`filer-container ${isClosed}`}>
        <div className="filter-header">
          <div className="flex justify-between">
            <h1>{t('translation.filters')}</h1>
            <button onClick={() => onToggle()} className="sm:hidden block">
              <FontAwesomeIcon icon={faSlidersH}/>
            </button>
          </div>
          {Object.keys(filters).length > 0 &&
                <button onClick={resetFilter} className="btn-reset-filters">{t('translation.clearAll')}
                  ({Object.keys(filters).length})</button>}
        </div>
        <div>
          <Collapsible trigger={t('translation.brandAndModel')}>
            <div className="collapsible-content-container">
              <label htmlFor="brand" className="mr-1">{t('translation.brand')}</label>
              <select id="brand" onChange={onBrandChange} value={selectedBrand || ''}>
                {brands && brands.length &&
                      <>
                        {brands.map(brand => (
                          <option value={brand['@id'] || null} key={brand['@id'] || brand.name}>
                            {brand.name}
                          </option>))}
                      </>
                }
              </select>
            </div>
            <div className="collapsible-content-container">
              <label htmlFor="model" className="mr-1">{t('translation.model')}</label>
              <select id="model" onChange={onModelChange} value={selectedModel || ''}>
                {models.map(model => <option value={model['@id'] || null}
                  key={model['@id'] || model.name}>
                  {model.name}
                </option>)}
              </select>
            </div>
          </Collapsible>
          {process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro' &&
            <>
              <Collapsible trigger={t('translation.gearbox')}>
                <div className="collapsible-content-container">
                  <select id="transmission" onChange={onTransmissionChange} value={selectedTransmission || ''}>
                    <option value="-1">{t('translation.all')}</option>
                    <option value="Manuelle">{t('translation.manual')}</option>
                    <option value="Automatique">{t('translation.automatic')}</option>
                  </select>
                </div>
              </Collapsible>
              <Collapsible trigger={t('translation.motorization')}>
                <div className="collapsible-content-container">
                  <select id="fuel" onChange={onFuelChange} value={selectedFuel || ''}>
                    <option value={'-1'}>{t('translation.all')}</option>
                    <option value={'hybride'}>{t('translation.hybrid')}</option>
                    <option value={'essence'}>{t('translation.essence')}</option>
                    <option value={'diesel'}>{t('translation.diesel')}</option>
                    <option value={'electrique'}>{t('translation.electric')}</option>
                  </select>
                </div>
              </Collapsible>
            </>
          }
          {process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro' &&
            <>
              <Collapsible trigger={t('translation.color')}>
                <div className="collapsible-content-container">
                  <select id="fuel" onChange={onColorChange} value={selectedColor || ''}>
                    <option value="-1">{t('translation.all')}</option>
                    <option value="silver">{t('translation.silver')}</option>
                    <option value="gold">{t('translation.gold')}</option>
                    <option value="pink">{t('translation.pink')}</option>
                    <option value="red">{t('translation.red')}</option>
                    <option value="blue">{t('translation.blue')}</option>
                    <option value="white">{t('translation.white')}</option>
                    <option value="black">{t('translation.black')}</option>
                    <option value="green">{t('translation.green')}</option>
                  </select>
                </div>
              </Collapsible>
              <Collapsible trigger={t('translation.storage')}>
                <div className="collapsible-content-container">
                  <select id="transmission" onChange={onStorageCapacityChange} value={selectedStorageCapacity || ''}>
                    <option value="-1">{t('translation.all')}</option>
                    <option value="8gb">8gb</option>
                    <option value="16g">16gb</option>
                    <option value="32gb">32gb</option>
                    <option value="64gb">64gb</option>
                    <option value="128gb">128gb</option>
                    <option value="256gb">256gb</option>
                    <option value="512gb">512gb</option>
                    <option value="1tb">1tb</option>
                  </select>
                </div>
              </Collapsible>
              {/*<Collapsible trigger="ÉTAT DE BATTERIE">*/}
              {/*  <div className="collapsible-content-container">*/}
              {/*    <select id="batteryStatus" onChange={onBatteryStatus} value={selectedBatteryStatus || ''}>*/}
              {/*      <option value="">ÉTAT DE BATTERIE</option>*/}
              {/*      <option value="Bon état">Bon état</option>*/}
              {/*      <option value="Mauvais état">Mauvais état</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}
              {/*</Collapsible>*/}
            </>
          }
          <Collapsible trigger={t('translation.price')}>
            <div className="collapsible-content-container">
              <div className="flex items-center justify-between gap-3">
                <input type="number" placeholder="Min" className="w-20 text-right placeholder:text-left"
                  onChange={onMinPriceChange} value={selectedMinPrice || ''}/>
                <span>-</span>
                <input type="number" placeholder="Max" className="w-20 text-right placeholder:text-left"
                  onChange={onMaxPriceChange} value={selectedMaxPrice || ''}/>
              </div>
            </div>
            {selectedMaxPrice && selectedMinPrice && selectedMinPrice > selectedMaxPrice &&
                  <small className="text-red-500 block text-left my-4" style={{fontSize: '10px'}}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/> {t('translation.minPriceMessage')} <br/> {t('translation.maxPriceMessage')}</small>}
          </Collapsible>
          {process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro' &&
                <>
                  <Collapsible trigger={t('translation.year')}>
                    <div className="collapsible-content-container">
                      <div className="flex items-center justify-between gap-3">
                        <input type="number" placeholder="Min" minLength="4"
                          className="w-20 text-right placeholder:text-left" onChange={onMinYearChange}
                          value={selectedMinYear || ''}/>
                        <span>-</span>
                        <input type="number" placeholder="Max" minLength="4"
                          className="w-20 text-right placeholder:text-left" onChange={onMaxYearChange}
                          value={selectedMaxYear || ''}/>
                      </div>
                    </div>
                  </Collapsible>
                  <Collapsible trigger={t('translation.mileage')}>
                    <div className="collapsible-content-container">
                      <div className="flex items-center justify-between gap-3">
                        <input type="number" placeholder="Min" className="w-20 text-right placeholder:text-left"
                          onChange={onMinMileageChange} value={selectedMinMileage || ''}/>
                        <span>-</span>
                        <input type="number" placeholder="Max" className="w-20 text-right placeholder:text-left"
                          onChange={onMaxMileageChange} value={selectedMaxMileage || ''}/>
                      </div>
                    </div>
                  </Collapsible>
                  <Collapsible trigger={t('translation.category')}>
                    <div className="collapsible-content-container">
                      <select
                        className="width-full p-2 border-2 border-gray-500 font-montserrat text-sm font-semibold  bg-white"
                        id="brand" onChange={onCategoryChange} value={selectedCategory || ''}>
                        {categories.map(category => (
                          <option value={category['@id'] || null} key={category['@id'] || category.name}>
                            {category.name}
                          </option>))}
                      </select>
                    </div>
                  </Collapsible>
                </>
          }
          <Collapsible trigger={t('translation.city')}>
            <div className="collapsible-content-container">
              <label htmlFor="brand" className="mr-1">{t('translation.city')}</label>
              <select id="city" onChange={onCityChange} value={selectedCity || ''}>
                {cities && cities.length &&
                  <>
                    {cities.map(city => (
                      <option value={city['@id'] || null} key={city['@id'] || city.name}>
                        {city.name}
                      </option>))}
                  </>
                }
              </select>
            </div>
          </Collapsible>
        </div>
      </div>
    </>
  );
}

ProductFilter.propTypes = {
  onFilter: PropTypes.func,
  onToggle: PropTypes.func,
  isClosed: PropTypes.string,
  defaultValues: PropTypes.object
};
