import axiosInstance from '../../services/axiosInstance';

export const getCollection = async (url) => {
  const response = await asyncFetchUrl(url);
  return response['hydra:member'] || [];
};

export const asyncFetchUrl = async (url) => {
  try {
    return (await axiosInstance.get(url)).data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getByIri = async (iri) => {
  return asyncFetchUrl(iri);
};