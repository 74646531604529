import Header from '../../components/common/Header';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMeAsync, selectMe} from '../../features/me/meSlice';
import {fetchCreditAsync, selectCredit} from '../../features/credit/creditSlice';
import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {fetchOrderById} from '../../features/order/orderAPI';
import {API_BASE_URL} from '../../services/axiosInstance';
import {selectToken} from '../../features/auth/authSlice';
import {formatNumberThreeDigits} from '../../helpers/util';
import {CREDIT_PRICE} from '../../components/common/contants';
import {useTranslation} from 'react-i18next';

function CreateOrder() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const credit = useSelector(selectCredit);
  const [order, setOrder] = useState([]);
  const params = useParams();
  const token = useSelector(selectToken);

  useEffect(() => {
    if (!me) {
      dispatch(fetchMeAsync());
    }
  }, []);

  useEffect(() => {
    if (me && !credit) {
      dispatch(fetchCreditAsync(me.credit));
    }
  }, [me]);

  useEffect(() => {
    const fetchData = async () => {
      setOrder(await fetchOrderById(params.id));
    };
    fetchData();
  }, [params]);

  const onPay = () => {
    window.location.replace(`${API_BASE_URL}/orders/${order.id}/payment_request?bearer=${token}`);
  };

  return (
    <div className="relative">
      <Header/>
      {order ?
        <div className=" sm:pl-0 md:ml-10 bg-white text-center py-10 h-screen">
          {order.status === 'unpaid' ?
            <>
              <h1 className="font-montserrat text-black text-center text-2xl uppercase font-semibold px-3 w-full">
                {t('translation.orderOf')} {order.credit} {t('translation.oportunities')}
              </h1>

              <p className="my-4 text-center uppercase">Total à payer: <strong className="text-[#225bd9]">{formatNumberThreeDigits(order.credit * CREDIT_PRICE)} DH</strong></p>

              {params.status === 'failurefailure' &&
                <p>{t('translation.anErrorHasOccurred')}</p>}

              <div className="flex justify-center my-8 pb-8">
                <button className="btn btn-rounded text-white uppercase font-semibold bg-[#0028d5]" onClick={onPay}>{t('translation.pay')}</button>
              </div>

            </>
            :
            <div className="p-8">
              {!params.status ?
                <p>{t('translation.OrderPayed')}</p>
                :
                <>
                  {params.status === 'success' ?
                    <p>{t('translation.orderPayedSuccessfully')}</p>
                    :
                    <p>{t('translation.anErrorHasOccurred')}</p>
                  }
                </>
              }
              <Link to="/orders">
                <button className="blue-button my-8">{t('translation.pay')}</button>
              </Link>
            </div>
          }
        </div>
        : 'loading...'}
    </div>
  );
}

export default CreateOrder;
