import LoginThumb from '../components/security/LoginThumb';
import ForgetPasswordForm from '../components/security/ForgetPasswordForm';
import LanguageSwitcher from '../components/LanguageSwitcher';

function ForgetPassword() {
  return (
    <div className="grid grid-cols-12">
      <div className="absolute top-0 right-0 p-10">
        <LanguageSwitcher/>
      </div>
      <div className="h-full min-h-full order-last col-span-12 md:order-first md:col-span-7 lg:col-span-8 bg-white flex content-center">
        <LoginThumb />
      </div>
      <div className="col-span-12 md:col-span-5 lg:col-span-4">
        <ForgetPasswordForm />
      </div>
    </div>
  );
}

export default ForgetPassword;
