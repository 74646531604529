import Header from '../../components/common/Header';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMeAsync, selectMe} from '../../features/me/meSlice';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Formik, Form as FormikForm} from 'formik';
import * as Yup from 'yup';
import {createCampaign} from '../../features/campaign/campaignAPI';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import ImageDropzone from '../../components/ImageDropzone';
import VideoDropzone from '../../components/VideoDropzone';
import { uploadImage, uploadVideo } from '../../features/uploadFiles/UploadFilesAPI';
import {useTranslation} from 'react-i18next';

const today = moment().format('YYYY-MM-DDThh:mm');



function CreateCampaign() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  // const [violations, setViolations] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  moment().format();

  const AppSchema = Yup.object().shape({
    name: Yup.string().required(`${t('translation.enterAName')}`),
    startTime: Yup.string().required(`${t('translation.specifyADate')}`),
    endTime: Yup.string().required(`${t('translation.specifyADate')}`),
    message: Yup.string(),
    channel: Yup.string(),
    photos: Yup.string(),
    videos : Yup.string()
  });

  useEffect(() => {
    if (!me) {
      dispatch(fetchMeAsync());
    }
  }, []);
  const handleImageChange = (image) => {
    setImages(image);
  };

  const handleVideoChange = (video) => {
    setVideos(video);
  };
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async (values, {setSubmitting}) => {
    /*async function sendAsyncRequest() {
          console.log('submitted');
          setTimeout(() => setSubmitting(false), 2000);
        }
        sendAsyncRequest();*/

    const imagesIri = [];
    const videosIri = [];

    for (let img of images) {
      const imgIri = await uploadImage(img);
      console.log('imgIri', imgIri['@id']);
      imagesIri.push(imgIri['@id']);
    }

    for (let vid of videos) {
      const videoIri = await uploadVideo(vid);
      console.log('video Iri', videoIri['@id']);
      videosIri.push(videoIri['@id']);
    }

    values['photos'] = imagesIri;
    values['videos'] = videosIri;

    const asyncSubmission = async () => {
      try {
        const response = await createCampaign(values);
        if (response.status === 201) {
          toast.success('Votre campagne a été créée avec succès');
          setTimeout(() => {
            setSubmitting(false);
            navigate('/campaigns');
          }, 1000);
        } else {
          setSubmitting(false);
          toast.error(
            response && response.data && response.data.message
              ? response.data.message
              : 'Erreur inconnu est survenu.'
          );
        }
      } catch (ex) {
        setSubmitting(false);
      }
    };
    asyncSubmission();
  };

  return (
    <>
      <Header/>
      <div className="sm:pl-0 md:ml-10 py-20 bg-white font-montserrat text-center">
        <h1 className="py-5  text-3xl font-semibold uppercase">
          {t('translation.createAnewCampaign')}
        </h1>
        <Formik
          initialValues={{name: ''}}
          validationSchema={AppSchema}
          onSubmit={handleSubmit}
        >
          {({errors, touched, handleBlur, handleChange, isValid, isSubmitting, values}) => (
            <FormikForm className="flex items-center flex-col relative text-black">
              <div className=" grid grid-cols-1 sm:grid-cols-2 gap-x-10 sm:px-0 px-4">
                <div>
                  <div className="flex items-start flex-col relative">
                    <label className="text-black">{t('translation.campaignName')}</label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder={t('translation.campaignName')}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`${
                        errors.name &&
                                        touched.name &&
                                        errors.name &&
                                        'border border-red-600'
                      } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                    />
                    <p className="text-red-600">
                      {errors.name}
                    </p>
                  </div>
                  <div className="flex items-start flex-col relative">
                    <label className="text-black">{t('translation.releaseDate')}</label>
                    <input
                      id="startTime"
                      name="startTime"
                      type="datetime-local"
                      placeholder={t('translation.releaseDate')}
                      min={today}
                      value={values.startTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`${
                        errors.startTime &&
                                        touched.startTime &&
                                        errors.startTime &&
                                        'border border-red-600'
                      } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                    />

                    <p className="text-red-600">
                      {errors.startTime}
                    </p>
                  </div>
                  <div className="flex items-start flex-col relative">
                    <label className="text-black">{t('translation.expirationDate')}</label>
                    <input
                      id="endTime"
                      name="endTime"
                      type="datetime-local"
                      placeholder={t('translation.releaseDate')}
                      min={moment(moment(),values.startTime).add(1, 'days').format('YYYY-MM-DDThh:mm')}
                      max={values.enterTime}
                      value={values.endTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`${
                        errors.endTime &&
                                        touched.endTime &&
                                        errors.endTime &&
                                        'border border-red-600'
                      } bg-white border-2 border-black p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                    />

                    <p className="text-red-600">
                      {errors.endTime}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="text-left">
                    <label htmlFor="channel" className="text-black ">{t('translation.channelType')}</label>
                    <select
                      name="channel"
                      onChange={handleChange}
                      className="w-full border-2 border-black bg-white rounded-lg p-3 my-3"
                      value={values.channel}
                    >
                      <option value="">{t('translation.selectYourChannel')}</option>
                      <option value="whatsapp">Whatsapp</option>
                      <option value="sms">SMS</option>
                      <option value="email">Email</option>
                    </select>
                    <p className="text-red-600">
                      {errors.channel}
                    </p>
                  </div>
                  <div className="flex items-start flex-col">
                    <label htmlFor="message" className="text-black">{t('translation.message')}</label>
                    <textarea
                      className="w-full p-3 border-2 border-black rounded-lg normal my-3"
                      placeholder={t('translation.message')}
                      name={t('translation.message')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    <p className="text-red-600">
                      {errors.message}
                    </p>
                  </div>

                  {values.channel === 'whatsapp' &&
              <>
                <ImageDropzone onChange={handleImageChange}/>
                <VideoDropzone onChange={handleVideoChange}/>
              </>
                  }
                </div>
              </div>
              <p className="text-right">
                <button
                  type="submit"
                  className="blue-button"
                  disabled={!isValid || isSubmitting}
                >
                  {!isSubmitting && t('translation.send')}
                  {isSubmitting && <span>{t('translation.sending')}</span>}
                </button>
              </p>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
}

export default CreateCampaign;
