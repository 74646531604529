import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import loginFormBg from '../../assets/images/bg-form.png';
import ocarzProLogo from '../../assets/images/ocarz-pro.svg';
import {sendResetPassword} from '../../features/password/resetPassword';
import {toast} from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom';
import switchyproLogo from '../../assets/images/switchy.png';
import {useTranslation} from 'react-i18next';



export default function ResetPasswordForm({hash}) {
  const {t} = useTranslation();
  const OCARZPRO = process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro';
  const SWITCHYPRO =  process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro';
  const navigate = useNavigate();
  
  const Schema = Yup.object().shape({
    password: Yup.string().required(`${t('translation.requiredField')}`),
    changePassword: Yup.string().when('password', {
      is: val => (val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        `${t('translation.passwordMatchesMessageValidation')}`
      )
    })
  });
  return (
    <Formik
      initialValues={{
        password: '',
        changePassword: ''
      }}
      validationSchema={Schema}
      onSubmit={async (values)  => {
        try {
          const res = await sendResetPassword(hash,values.password, values.changePassword);
          if(res.status === 200) {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate('/products');
            },2000);
          }
        } catch (ex) {
          console.log('errors', ex.response);
          if(ex.response.status === 422) {
            toast.error(`${t('translation.passwordBroken')}`);
          }
        }

      }}
    >
      {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
        return (
          <div className={'flex items-center sm:justify-center justify-start flex-col md:py-18 sm:max-h-max'} style={{background: `url(${loginFormBg}) no-repeat center center/cover` }}>
            <div className="lg:mb-22 my-24">
              <Link to="/">
                <img src={SWITCHYPRO && switchyproLogo || OCARZPRO && ocarzProLogo} className="mx-auto" alt=""/>
              </Link>
            </div>
            <form className="flex items-center flex-col text-white py-4 " onSubmit={handleSubmit}>
              <h1 className="text-lg text-center text-white">{t('translation.resetPassword')}</h1>
              <input
                type="password"
                name="password"
                className={`bg-white p-3 rounded my-3 text-gray-500 ${errors.password && 'border-2 border-red-500'} `}
                placeholder={t('translation.newPassword')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
              {errors.password &&
              <span className="bg-white rounded px-2 py-2 mb-2 text-center" style={{ color: 'red' }}>
                {errors.password}
              </span>
              }
              <input
                type="password"
                name="changePassword"
                className={`bg-white p-3 rounded my-3 text-gray-500 ${errors.changePassword && 'border-2 border-red-500'} `}
                placeholder={t('translation.confirmNewPassword')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.changePassword}
              />
              {errors.changePassword &&
              <span className="bg-white rounded px-2 py-2 mb-2 text-center" style={{ color: 'red' }}>
                {errors.changePassword}
              </span>
              }
              <button className="btn-form" type="submit">{t('translation.send')}</button>
            </form>
          </div>

        );
      }}
    </Formik>
  );
}

ResetPasswordForm.propTypes = {
  hash: PropTypes.string.isRequired
};
