import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export default function Sort({onSort}) {
  const sortRef = useRef(null);
  const {t} = useTranslation();

  useEffect(() => {
    if (!sortRef.current) return;
    onSort(sortRef.current.value);
  }, [sortRef.current]);

  return (
    <div className="sort flex flex-row items-center gap-10">
      <label htmlFor="productSort" className="text-custom-blue whitespace-nowrap mr-2">{t('translation.sortBy')}</label>
      <select id="productSort" onChange={(e) => onSort(e.target.defaultValue)} ref={sortRef}>
        <option value="order[createdAt]=desc">{t('translation.mostRecent')}</option>
        <option value="order[createdAt]=asc">{t('translation.theOldest')}</option>
      </select>
    </div>
  );
}

Sort.propTypes = {
  onSort: PropTypes.func
};
