import {useNavigate, useParams} from 'react-router-dom';
import {verifyEmail} from '../features/verifyEmail/verifyEmailAPI';
import {useEffect} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import {useTranslation} from 'react-i18next';

function VerifyEmail() {
  const {t} = useTranslation();
  const {hash} = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    verifyEmail(hash)
      .then((response) => {
        if (response && response.status === 200) {
          toast.success(`${t('translation.emailVerified')}`);
          navigate('/login');
        } else {
          toast.error(response.data['hydra:description']);
        }
      }).catch(() => {
        toast.error(`${t('translation.anErrorHasOccurred')}`);
      });
  }
  , []);

  return (
    <>
      <p>loading...</p>
      <ToastContainer/>
    </>
  );
}

export default VerifyEmail;
