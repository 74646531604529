import axios from 'axios';
import navigationService from './NavigationService';
import {logout} from '../features/auth/authSlice';

export const API_BASE_URL = process.env.REACT_APP_API_HOST;

let store;

export const injectStore = _store => {
  store = _store;
  return store;
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {'content-type': 'application/ld+json'},
  validateStatus: status => (status >= 200 && status < 300) || status === 422 || status === 400
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization =  `bearer ${store.getState().auth.token}`;
  return config;
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    store.dispatch(logout());
    navigationService.navigate('/login');
  }
});

export default axiosInstance;
