import PropTypes from 'prop-types';

const Button = ({ label, btnClass, type }) => {
  return (
    <>
      <button type={type} className={`btn ${btnClass}`}>{label}</button>
    </>
  );
};

export default Button;

Button.propTypes = {
  label: PropTypes.string,
  btnClass: PropTypes.string,
  type: PropTypes.string,
};
