import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMe } from './meAPI';

const initialState = {
  value: null,
  status: 'idle',
};

export const selectMe = (state) => state.me.value;

export const fetchMeAsync = createAsyncThunk(
  'me/fetchMe',
  async () => {
    return await fetchMe();
  }
);

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    destroyMe: (state) => {
      state.value = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      });
  },
});

export default meSlice.reducer;
