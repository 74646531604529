import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import {register} from '../features/register/registerAPI';
import {toast, ToastContainer} from 'react-toastify';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
const platform = process.env.REACT_APP_TARGETED_PLATFORM;

export function SignUpForm() {
  const {t} = useTranslation();
  const Schema = Yup.object().shape({
    email: Yup.string()
      .email(`${t('translation.invalidEmail')}`)
      .required(`${t('translation.required')}`),
    password: Yup.string()
      .required(`${t('translation.required')}`),
    confirmPassword: Yup.string()
      .required(`${t('translation.required')}`)
      .when('password', {
        is: val => (val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          `${t('translation.passwordMatchesMessageValidation')}`
        )
      })
  });
  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, {resetForm}) => {
          register(values)
            .then((response) => {
              if (response.status === 201) {
                toast.success(`${t('translation.accountCreated')}`);
                resetForm();
              } else {
                toast.error(`${t('translation.anErrorHasOccurred')}`);
              }
            })
            .catch((ex) => {
              toast.error(`${ex.response.data['hydra:description']}`);
            });
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
        }) => {
          return (

            <div className="flex items-center justify-center flex-col md:py-18 sm:max-h-max py-8 h-full">
              <h1 className="text-white uppercase text-center font-bold">{t('translation.createMyAccount')}</h1>
              <form className="flex items-center flex-col relative text-white" onSubmit={handleSubmit}>
                <div className="block w-full">
                  <div className="flex items-center flex-col relative">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder={t('translation.email')}
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      className={`${errors.email && touched.email && errors.email && 'border border-red-600'} bg-white p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                    />
                    <span
                      className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600"><ErrorMessage
                        name="email"/></span>
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-2 w-full">
                  <div className="flex items-center flex-col relative">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder={t('translation.password')}
                      className={`${errors.password && touched.password && errors.password && 'border border-red-600'} bg-white p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                      onChange={handleChange}
                      value={values.password}
                      onBlur={handleBlur}
                    />
                    <span
                      className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600"><ErrorMessage
                        name="password"/></span>
                  </div>
                  <div className="flex items-center flex-col relative">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder={t('translation.confirmPassword')}
                      className={`${errors.confirmPassword && touched.confirmPassword && errors.confirmPassword && 'border border-red-600'} bg-white p-3 rounded my-3 text-gray-500 w-full font-semibold`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                    />
                    <span
                      className="absolute right-0 top-0 px-3 py-6 text-xs text-red-600"><ErrorMessage
                        name="confirmPassword"/></span>
                  </div>
                </div>
                <button className="btn-form font-bold" type="submit">{t('translation.subscribe')}</button>
                <div className="my-8 font-axis text-center text-xs">
                  <p className="mb-4">
                    {t(`${platform}.signUpHint`)}
                  </p>
                  <Link to="/login">
                    <button className="rounded text-white p-3 px-6 bg-blue-600" >
                      {t('translation.login')}
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
      <ToastContainer />
    </>
  );
}
