import PropTypes from 'prop-types';
import {formatNumberThreeDigits} from '../../helpers/util';
import iconGearbox from '../../assets/images/icon-gearbox.svg';
import iconCalendar from '../../assets/images/icon-callendar.svg';
import iconKM from '../../assets/images/icon-km.svg';
import iconFuel from '../../assets/images/icon-fuel.svg';
import iconEngine from '../../assets/images/icon-engine.svg';
import {useTranslation} from 'react-i18next';

function ProductMainInfo({transmission, mileage, year, fuel, engine, brand, storageCapacity, color, firstHand, flexBasis}) {
  const {t} = useTranslation();

  return (
    <div className={`bg-white w-full flex flex-col lg:flex-row xl:flex-col items-stretch xl:flex-nowrap lg:flex-wrap gap-4 py-0 ${flexBasis}`}>
      {year &&
      <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
        <img src={iconCalendar} alt="calendar icon"/>
        <div className="flex item-center flex-end flex-col justify-center text-right">
          <h3 className="font-montserrat font-semibold text-sm">{year}</h3>
          <p className="text-xs font-montserrat font-light text-gray-8d8d8d">
            {t('translation.release')}
          </p>
        </div>
      </div>}
      {mileage &&
      <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
        <img src={iconKM} alt=""/>
        <div className="flex item-center flex-end flex-col justify-center text-right">
          <h3 className="font-montserrat font-semibold text-sm">{formatNumberThreeDigits(mileage)} <small>km</small></h3>
          <p className="text-xs font-montserrat font-light text-gray-8d8d8d">{t('translation.mileage')}</p>
        </div>
      </div>}
      {fuel &&
      <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
        <img src={iconFuel} alt=""/>
        <div className="flex item-center flex-end flex-col justify-center text-right">
          <h3 className="font-montserrat font-semibold text-sm">{fuel}</h3>
          <p className="text-xs font-montserrat font-light text-gray-8d8d8d">{t('translation.fuel')}</p>
        </div>
      </div>}
      {transmission &&
      <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
        <img src={iconGearbox} alt="Fuel icon"/>
        <div className="flex item-center flex-end flex-col justify-center text-right">
          <h3 className="font-montserrat font-semibold text-sm">{t(`translation.${transmission}`)}</h3>
          <p className="text-xs font-montserrat font-light text-gray-8d8d8d">{t('translation.gearbox')}</p>
        </div>
      </div>}
      {engine &&
      <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
        <img src={iconEngine} alt=""/>
        <div className="flex item-center flex-end flex-col justify-center text-right">
          <h3 className="font-montserrat font-semibold text-sm">{engine}</h3>
          <p className="text-xs font-montserrat font-light text-gray-8d8d8d"> {t('translation.engine')}</p>
        </div>
      </div>}

      {process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro' &&
      <>
        {brand &&
          <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
            <div className="flex item-center flex-end flex-col justify-center text-right">
              <h3 className="font-montserrat font-semibold text-sm">{brand}</h3>
              <p className="text-xs font-montserrat font-light text-gray-8d8d8d"> {t('translation.brand')}</p>
            </div>
          </div>}
        {storageCapacity &&
          <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
            <div className="flex item-center flex-end flex-col justify-center text-right">
              <h3 className="font-montserrat font-semibold text-sm">{storageCapacity}</h3>
              <p className="text-xs font-montserrat font-light text-gray-8d8d8d"> {t('translation.storageCapacity')}</p>
            </div>
          </div>}
        {color &&
          <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
            <div className="flex item-center flex-end flex-col justify-center text-right">
              <h3 className="font-montserrat font-semibold text-sm">{t(`translation.${color}`)}</h3>
              <p className="text-xs font-montserrat font-light text-gray-8d8d8d"> {t('translation.color')}</p>
            </div>
          </div>}
        <div className="bg-blue-d8ebfc rounded p-4 flex items-center flex-row justify-between gap-6 flex-grow shadow-sm">
          <div className="flex item-center flex-end flex-col justify-center text-right">
            <h3 className="font-montserrat font-semibold text-sm">{firstHand ? t('translation.yes') : t('translation.no')}</h3>
            <p className="text-xs font-montserrat font-light text-gray-8d8d8d"> {t('translation.firstHand')}</p>
          </div>
        </div>
      </>
      }
    </div>
  );
}

export default ProductMainInfo;

ProductMainInfo.propTypes = {
  transmission: PropTypes.string,
  mileage: PropTypes.number,
  year: PropTypes.number,
  fuel: PropTypes.string,
  engine: PropTypes.string,
  brand: PropTypes.string,
  storageCapacity: PropTypes.string,
  color: PropTypes.string,
  firstHand: PropTypes.bool,
  flexBasis: PropTypes.string,
};
