import {useEffect, useRef, useState} from 'react';
import ProductListItem from './ProductListItem';
import ProductFilter from './productFilter';
import {useNavigate} from 'react-router-dom';
import ProductSort from './productSort';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMeAsync, selectMe} from '../../features/me/meSlice';
import PerPage from '../common/PerPage';
import {fetchProducts} from '../../features/products/productAPI';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft, faSlidersH} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import AddProductsToCampaignDialog from './AddProductsToCampaignDialog';
import CircularIndeterminate from '../CircularIndeterminate';
import {useTranslation} from 'react-i18next';

export default function ProductList({showUserProductsOnly}) {
  const queryParams = window.location.search;
  const urlParams = new URLSearchParams(queryParams);
  const filtersParams = JSON.parse(urlParams.get('filters'));
  const { width } = useWindowDimensions();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(urlParams.get('page') ? urlParams.get('page') : 1);
  const [countProducts, setCountProducts] = useState(0);
  const [closed, setIsClosed] = useState(false);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState(urlParams.get('sort') ? urlParams.get('sort') : null);
  const [perPage, setPerPage] = useState(urlParams.get('perpage') ? urlParams.get('perpage') : null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const fetchProductDelay = useRef(null);

  // const querySort = urlParams.get('sort');

  useEffect(() => {
    if (!me) {
      dispatch(fetchMeAsync());
    }
  }, []);

  const toggleSideBar = () => {
    if (closed === false) {
      setIsClosed(true);
    } else {
      setIsClosed(false);
    }
  };

  useEffect(
    () => {
      if (!page || !perPage || !sort) {
        return;
      }
      if (showUserProductsOnly === true && !me) return;

      async function fetchData() {
        setIsLoading(true);
        const query = new URLSearchParams(filters);
        let keysForDel = [];

        query.forEach((value, key) => {
          if (value === '') {
            keysForDel.push(key);
          }
        });

        keysForDel.forEach(key => {
          query.delete(key);
        });

        window.history.pushState('', '', `/products?page=${page}&perpage=${perPage}&sort=${sort ? `${encodeURIComponent(`${sort}`)}&filters=${JSON.stringify(filters)}` : ''}`);

        const response = await fetchProducts(`/products?${showUserProductsOnly === true ? `consumeCreditActions.user=${me['@id']}` : ''}${showUserProductsOnly !== true ? '&isConsumed=false' : ''}&page=${page}&itemsPerPage=${perPage}${sort ? `&${sort}` : ''}&${query}`);
        if (response.status === 200) {
          setIsLoading(false);
          setProducts(response.data['hydra:member']);
          setPageCount(Math.ceil(response.data['hydra:totalItems'] / perPage));
          setCountProducts(response.data['hydra:totalItems']);
        }
      }
      if (fetchProductDelay.current) {
        clearTimeout(fetchProductDelay.current);
      }
      fetchProductDelay.current = setTimeout(() => {
        fetchData();
      }, 2000);
    },
    [filters, page, sort, perPage, me, showUserProductsOnly],
  );

  useEffect(() => {
    setIsLoading(true);
  }, [filters,page,sort,perPage,showUserProductsOnly,me]);

  const onViewProductDetail = (product) => {
    navigate(`/products/${encodeURIComponent(product['@id'])}`);
  };

  const handleOnFilter = (filter) => {
    setFilters(filter);
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const onSort = (sort) => {
    setSort(sort);
  };

  const onPerPage = (perPage) => {
    setPage(1);
    setPerPage(perPage);
  };
  const {t} = useTranslation();
  const PLATEFORM = process.env.REACT_APP_TARGETED_PLATFORM;

  return (
    <div className="custom-gray-bg sm:pl-0 md:ml-10 flex">
      <ProductFilter onFilter={handleOnFilter} onToggle={toggleSideBar} isClosed={closed ? 'hidden' : 'block'} defaultValues={filtersParams}/>
      <div className="bg-white mx-auto relative min-h-screen w-full">
        <div className="px-5 md:px-12">
          <div className="flex flex-col sm:flex-row justify-between sm:items-center">
            <div>
              <div className="flex items-center flex-start gap-4">
                <button onClick={() => toggleSideBar()}>
                  <FontAwesomeIcon icon={faSlidersH}/>
                </button>
                <h1
                  className="uppercase text-blue-800 font-axis-bold text-3xl my-7"> {countProducts ? `${countProducts} ${t(`${PLATEFORM}.carsForSale`)}` : `${t(`${PLATEFORM}.noVehiclesAvailable`)}`}</h1>
              </div>
            </div>
            {Object.keys(filters).length > 0 &&
                    <AddProductsToCampaignDialog
                      dialogTitle={t('translation.addProductsToACampaign')}
                      dialogContentText={t('translation.campaign')}
                      buttonLabel={t('translation.add')}
                      filters={filters}/>}
            <ProductSort onSort={onSort} defaultValue={sort}/>
          </div>
          {isLoading && <CircularIndeterminate />}
          <>
            <div
              className={`grid ${closed ? 'sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4' : 'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3'}  gap-4 my-4`}>
              {products && products.length > 0 && products.map(product => <ProductListItem product={product} onViewDetail={onViewProductDetail}
                key={product.id}/>)}
            </div>

            <div>
              <PerPage onPerPage={onPerPage} defaultValue={perPage}/>&nbsp;
              {pageCount > 1 && (
                <ReactPaginate className="pagination"
                  breakLabel="..."
                  nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  pageRangeDisplayed={width < 767 ? 1 : 2}
                  marginPagesDisplayed={width < 767 ? 1 : 2}
                  previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                  renderOnZeroPageCount={null}
                />
              )}
              <div/>
            </div>
          </>
        </div>
      </div>

    </div>
  );
}

ProductList.propTypes = {
  showUserProductsOnly: PropTypes.bool,
};
