import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const TechnicalSheet = ({specs}) => {
  const [data, setData] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    console.log('specs', specs);
    if (!specs) return;
    const spec = specs.filter(spec => spec.key.includes('datasheet'));
    if(spec.length !== 0) {
      setData(spec[0].data);
    }
  },[specs]);

  return (
    <div className="px-4 my-10">
      {data &&
        <>
          <h1 className="text-2xl font-semibold font-montserrat mb-4">{t('translation.productDataSheet')}</h1>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-10 gap-x-10 gap-y-4">
            {!data ? <span/> : data.map(({key, label, value}) =>
              <p key={key} className="border-b-2 border-[#1ea0fb] flex justify-between">
                <span className="text-2x font-bold text-[#1ea0fb] mr-4">{t(`translation.${label}`)}:</span>
                <span className="text-right">{value}</span>
              </p>
            )}
          </div>
        </>
      }
    </div>
  );
};

export default TechnicalSheet;

TechnicalSheet.propTypes = {
  specs: PropTypes.array.isRequired,
};
