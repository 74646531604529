import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export default function ProductSort({onSort, defaultValue}) {
  const sortRef = useRef(null);
  const {t} = useTranslation();

  useEffect(() => {
    if (!sortRef.current) return;
    onSort(sortRef.current.value);
  }, [sortRef.current]);

  return (
    <div className="sort flex flex-row items-center gap-10">
      <label htmlFor="productSort" className="text-custom-blue whitespace-nowrap mr-2">{t('translation.sortBy')}</label>
      <select id="productSort" onChange={(e) => onSort(e.target.value)} ref={sortRef} value={defaultValue ? defaultValue : ''}>
        <option value="order[createdAt]=desc">{t('translation.mostRecent')}</option>
        <option value="order[createdAt]=asc">{t('translation.theOldest')}</option>
        <option value="order[price]=asc">{t('translation.theCheapest')}</option>
        <option value="order[price]=desc">{t('translation.theMostExpensive')}</option>
      </select>
    </div>
  );
}

ProductSort.propTypes = {
  onSort: PropTypes.func,
  defaultValue:PropTypes.any
};
