import ocarzLogo from '../../assets/images/ocarz-logo.svg';
import switchypro from '../../assets/images/switchy.png';
import {Link, Navigate} from 'react-router-dom';
import useIsConnected from '../../hooks/useIsConnected';
import AccountMenu from '../AccountMenu';
import navigationService from '../../services/NavigationService';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../features/auth/authSlice';
import {fetchMeAsync, selectMe} from '../../features/me/meSlice';
import {useEffect} from 'react';
import {fetchCreditAsync, selectCredit} from '../../features/credit/creditSlice';
import Credit from '../Credit';
import pandaMascot from '../../assets/images/panda.svg';
import {useTranslation} from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';

const Header = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const credit = useSelector(selectCredit);
  const PLATFORM = process.env.REACT_APP_TARGETED_PLATFORM;

  useEffect(() => {
    if (!me) {
      dispatch(fetchMeAsync());
    }
  }, []);

  useEffect(() => {
    if (me && !credit && me.credit) {
      dispatch(fetchCreditAsync(me.credit));
    }
  }, [me]);

  const onLogout = () => {
    dispatch(logout());
    navigationService.navigate('/login');
  };

  const isConnected = useIsConnected();
  if(!isConnected) return <Navigate to="/login"/>;

  return (
    <header className="p-4 sm:py-4 sm:px-8 sm:ml-8 ">
      <div className="responsive-flex">
        <ul className="flex items-baseline gap-x-10">
          <li>
            <Link to={isConnected ? '/products' : '/'}>
              <img className="w-1/2 sm:w-auto max-w-[200px]" src={PLATFORM === 'ocarzpro' && ocarzLogo || PLATFORM === 'switchypro' && switchypro} alt="Ocarz"/>
            </Link>
          </li>
          {PLATFORM === 'ocarzpro' &&
          <li className="z-50">
            <img className="-mb-[25px]  hidden sm:block" src={pandaMascot} alt=""/>
          </li>
          }
        </ul>
        <div className="header-title flex-column hidden lg:block">
          <span>{t(`${PLATFORM}.vehicleHeaderTitle`)}</span>
        </div>
        {!isConnected ?
          <Link to="/login" className="text-primary-yellow">
            {t('translation.login')}</Link>
          :
          <div className="flex items-center align-center gap-10">
            {credit &&
                <Credit credit={credit} />
            }
            <LanguageSwitcher />
            <AccountMenu logout={onLogout} me={me} />
          </div>
        }
      </div>
    </header>
  );
};

export default Header;
