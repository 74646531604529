import { configureStore } from '@reduxjs/toolkit';
import meReducer from './features/me/meSlice';
import authReducer from './features/auth/authSlice';
import creditReducer from './features/credit/creditSlice';
import profileReducer from './features/profile/profileSlice';

const store = configureStore({
  reducer: {
    me: meReducer,
    auth: authReducer,
    credit: creditReducer,
    profile: profileReducer
  },
});

export default store;
