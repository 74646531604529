import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {fetchProductOwnerContactInfo} from '../../features/products/productAPI';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import {useTranslation} from 'react-i18next';

export default function ShowProductOwnerInfoDialog({customCSSClasses, productIri, onContact}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowProductContactInfo = async (type) => {
    try {
      const response = await fetchProductOwnerContactInfo(productIri, type);
      if (response.status === 200) {
        handleClose();
        onContact(response.data);
        toast.success(`${t('translation.successfullyReceivedYourInquiry')}`);
      } else {
        toast.error(response.data['hydra:description']);
      }
    } catch (ex) {
      toast.error(  `${t('translation.anErrorHasOccurred')}: ${ex.message}`);
    }
  };

  const {t} = useTranslation();
  return (
    <>
      <button className={customCSSClasses} onClick={handleClickOpen}>
        <PermPhoneMsgIcon  /> {t('translation.showContact')}
      </button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ m: 0, p: 2, pr: 7 }}>
          {t('translation.vehicleOwnerInfoDialogPhrase')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => handleShowProductContactInfo('normal')}>
            {t('translation.share')}
          </Button>
          <Button onClick={() => handleShowProductContactInfo('exclusive')} autoFocus>
            {t('translation.exclusively')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ShowProductOwnerInfoDialog.propTypes = {
  customCSSClasses: PropTypes.string.isRequired,
  productIri: PropTypes.string.isRequired,
  onContact: PropTypes.func.isRequired,
};
