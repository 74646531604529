import {useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';

export default function LanguageSwitcher() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [enableSwitcher, setEnableSwitcher] = React.useState(null);

  React.useEffect(() => {
    if(process.env.REACT_APP_ENABLE_LOCAL_SWITCHER === 'true') {
      setEnableSwitcher(true);
    } else {
      setEnableSwitcher(false);
    }
  },[]);

  const {i18n} = useTranslation();

  function TranslateClick(lang) {
    i18n.changeLanguage(lang);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  console.log('lang', i18n);
  return (
    <>
      {enableSwitcher
        &&
        <div>
          <button aria-describedby={id} onClick={handleClick}>
            {i18n.language === 'fr' ? <span>🇫🇷</span> : <span>🇺🇸</span>}
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{
              display:'grid'
            }}>
              <Button onClick={() => TranslateClick('en')}>🇺🇸</Button>
              <Button onClick={() => TranslateClick('fr')}>🇫🇷</Button>
            </Box>
          </Popover>
        </div>
      }
    </>
  );
}
