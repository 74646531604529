import { Formik } from 'formik';
import { sendEmailForgetPassword } from '../../features/password/forgetPassword';
import loginFormBg from '../../assets/images/bg-form.png';
import ocarzProLogo from '../../assets/images/ocarz-pro.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import {useTranslation} from 'react-i18next';
import switchyproLogo from '../../assets/images/switchy.png';

export default function ForgetPasswordForm() {
  const {t} = useTranslation();
  const [message, setMessage] = useState(null);
  const OCARZPRO = process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro';
  const SWITCHYPRO =  process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro';

  return (
    <Formik
      initialValues={{ email: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = `${t('translation.requiredField')}`;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = `${t('translation.invalidEmail')}`;
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const res = await sendEmailForgetPassword(values.email);
          if (res && res.status === 204) {
            setSubmitting(false);
            setMessage(
              `${t('translation.if')} ${values.email} ${t('translation.emailAlreadyExists')}`
            );
          }
        } catch (ex) {
          console.log('error', ex);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div
          className="font-axis flex items-center justify-center flex-col md:py-18 sm:max-h-max py-8 h-full px-10"
          style={{
            background: `url(${loginFormBg}) no-repeat center center/cover`,
          }}
        >
          <div className="my-10">
            <Link to="/">
              <img src={SWITCHYPRO && switchyproLogo || OCARZPRO && ocarzProLogo} className="mx-auto max-w-[200px]" alt=""/>
            </Link>
          </div>
          {!message ? (
            <form
              className="flex items-center flex-col text-white"
              onSubmit={handleSubmit}
            >
              <div className="text-center">
                <h1 className="font-axis text-white py-5 text-2xl tracking-wider">
                  {t('translation.forgetPassword')}
                </h1>
                <p className="py-5 text-center text-sm font-semibold">
                  {t('translation.forgetPasswordFormHint')}
                </p>
              </div>
              <div className="flex items-center flex-col font-axis w-full">
                {errors.email && (
                  <small className="text-red-600 font-semibold rounded py-1 px-4 mb-3">
                    {errors.email && touched.email && errors.email}
                  </small>
                )}
                <input
                  type="email"
                  name="email"
                  placeholder={t('translation.email')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={`${
                    errors.email && 'border-[1px] border-red-600'
                  } bg-white p-4 rounded my-3 text-gray-500 w-full`}
                />
              </div>
              <button
                className="btn-form"
                type="submit"
                disabled={isSubmitting}
              >
                {t('translation.send')}
              </button>
              <Link to="/login" className="font-axis text-sm py-5">{t('translation.backToLogin')}</Link>
            </form>
          ) : (
            <p className="text-white p-4">{message}</p>
          )}
        </div>
      )}
    </Formik>
  );
}
