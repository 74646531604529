import Box from '@mui/material/Box';
import ProfileForm from '../components/forms/ProfileForm';
import Header from '../components/common/Header';

export default function UpdateProfile() {
  return (
    <>
      <Header />
      <Box sx={{width:'100%',height:'90vh',background:'#FFFFFF', px:'2rem'}}>
        <ProfileForm />
      </Box>
    </>
  );
}
