import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMe } from '../me/meAPI';
import {updateProfile} from './profileAPI';

const initialState = {
  value: null,
  status: 'idle',
};

export const selectProfile = (state) => state.profile.value;

export const fetchProfileAsync = createAsyncThunk(
  'profile/fetchProfile',
  async () => {
    return await fetchMe();
  }
);

export const updateProfileAsync = createAsyncThunk(
  'profile/updateProfile',
  async (profile) => {
    return await updateProfile(profile);
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    destroyProfile: (state) => {
      state.value = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfileAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      });
  },
});

export default profileSlice.reducer;
