import PropTypes from 'prop-types';

const CreditCounter = ({
  label,
  type,
  name,
  placeholder,
  onChange,
  onBlur,
  counter,
  setCounter,
}) => {
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    if(counter > 1) {
      setCounter(counter - 1);
    } else {
      setCounter(1);
    }
  };

  return (
    <>
      <p className="pb-3">{label}</p>

      <div className="credit__counter__container">
        <span className="counter__btn" onClick={handleDecrement}>
          -
        </span>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={counter}
        />
        <span className="counter__btn" onClick={handleIncrement}>
          +
        </span>
      </div>
    </>
  );
};

export default CreditCounter;

CreditCounter.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
  setCounter: PropTypes.func.isRequired,
};
