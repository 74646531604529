import axios from 'axios';
import {API_BASE_URL} from '../../services/axiosInstance';

export const sendResetPassword = async (hash, newPassword, reNewPassword) => {
  const data = {hash, newPassword, reNewPassword};
  return (await axios({
    method:'POST',
    url: `${API_BASE_URL}/reset_password`,
    data
  }));
};