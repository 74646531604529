export const API_BASE_URL = process.env.REACT_APP_API_HOST;

export function uploadImage(image) {
  const body = new FormData();
  body.append('file', image);
  return (fetch(`${API_BASE_URL}/images`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem('token')}`
    }),
    method: 'POST'
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
}


export function uploadVideo(videos) {
  const body = new FormData();
  body.append('file', videos);
  return (fetch(`${API_BASE_URL}/videos`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem('token')}`
    }),
    method: 'POST'
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
} 