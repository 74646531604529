import axiosInstance from '../../services/axiosInstance';

export const sendPriceOffer = async (productIri, price) => {
  const data = {product: productIri, price};
  return (await axiosInstance({
    method: 'POST',
    url: '/offer_price_actions',
    data
  }));
};
