import {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Sort from '../../components/order/sort';
import {fetchCampaigns} from '../../features/campaign/campaignAPI';
import {useSelector} from 'react-redux';
import {selectToken} from '../../features/auth/authSlice';
import {API_BASE_URL} from '../../services/axiosInstance';
import Header from '../../components/common/Header';
import PerPage from '../../components/common/PerPage';
import {useTranslation} from 'react-i18next';

export default function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(null);
  const [countCampaigns, setCountCampaigns] = useState(0);
  const [sort, setSort] = useState(null);
  const token = useSelector(selectToken);
  const {t} = useTranslation();
  useEffect(
    () => {
      async function fetchData() {
        if (!page || !perPage || !sort) {
          return;
        }
        const response = await fetchCampaigns(page, perPage, sort);
        setCampaigns(response['hydra:member']);
        setPageCount(Math.ceil(response['hydra:totalItems'] / perPage));
        setCountCampaigns(response['hydra:totalItems']);
      }

      fetchData();
    },
    [page, sort, perPage],
  );

  /*const onCampaignViewDetail = (campaign) => {
      navigate(`/campaigns/${encodeURIComponent(campaign['@id'])}`);
    };*/

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const onSort = (sort) => {
    setSort(sort);
  };

  const onPerPage = (perPage) => {
    setPage(1);
    setPerPage(perPage);
  };

  function handleOnPublish(campaign) {
    window.location.replace(`${API_BASE_URL}/campaigns/${campaign.id}/publish?bearer=${token}`);
  }

  return (
    <div className="2xl:container sm:pl-0 md:ml-10">
      <Header/>
      <div className="bg-white mx-auto relative min-h-screen w-full">
        <div className="px-5">
          <div className="flex flex-col sm:flex-row justify-between sm:items-center">
            <div>
              <div className="flex items-center flex-start gap-4">
                <h1
                  className="uppercase text-blue-800 font-axis-bold text-3xl my-7"> {countCampaigns ? `${countCampaigns} campagnes` : `${t('translation.NoCampaignsAvailable')}`} </h1>
              </div>
            </div>
            <Sort onSort={onSort}/>
          </div>
          <div className="flex flex-row-reverse my-3 justify-center md:justify-end">
            <Link to="/campaigns/create" className="green-button text:sm">
              <button>{t('translation.addCampaign')}</button>
            </Link>
          </div>
          {campaigns &&
                        <div className="overflow-x-auto relative my-4">
                          <table className="bcampaign-collapse w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                              <tr>
                                <th className="table-heading">{t('translation.campaignName')}</th>
                                <th className="table-heading">{t('translation.date')}</th>
                                <th className="table-heading">{t('translation.totalLeadsOrdered')}</th>
                                <th className="table-heading">{t('translation.totalLeadAvailable')}</th>
                                <th className="table-heading">{t('translation.status')}</th>
                                <th className="table-heading"/>
                              </tr>
                            </thead>
                            <tbody>
                              {campaigns.map(campaign =>
                                <tr key={campaign.id}>
                                  <td className="table-cell">{campaign.name}</td>
                                  <td className="table-cell">{(new Date(campaign.createdAt)).toLocaleString('fr-FR')}</td>
                                  <td className="table-cell">{campaign.totalOrderedProductsCount}</td>
                                  <td className="table-cell">{campaign.totalProductsCount}</td>
                                  <td className="table-cell">{campaign.status}</td>
                                  <td className="table-cell">
                                    <button className="blue-button"
                                      onClick={() => handleOnPublish(campaign)}>{t('translation.publish')}</button>
                                  </td>
                                  <td className="table-cell">
                                    <Link to={`/campaigns/config/${campaign.id}`}>
                                      <button className="blue-button">{t('translation.update')}</button>
                                    </Link>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>}
          <div className="flex justify-center justify-between xl:my-10 lg:my-14 xl:py-2 lg:py-2 max-w-[100%]">
            <PerPage onPerPage={onPerPage} perPages={[10, 20, 30, 50]}/>&nbsp;
            {pageCount > 1 && (
              <ReactPaginate className="pagination"
                breakLabel="..."
                nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>}
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Campaigns.propTypes = {
  showUserProductsOnly: PropTypes.bool,
};
