import {useTranslation} from 'react-i18next';

function Copyright() {
  const {t} = useTranslation();
  const PLATFORM_NAME = process.env.REACT_APP_TARGETED_PLATFORM.toUpperCase();
  return (
    <>
      <div className="flex sm:flex-row flex-col sm:items-center items-start justify-between gap-4 mt-10 font-axis-bold text-white text-xs">
        <p>{PLATFORM_NAME}© 2022. {t('translation.AllRightsReserved')}</p>
        <ul className="flex sm:items-center items-start sm:flex-row flex-col sm:justify-center justify-start gap-4">
          <li><a href="#">{t('translation.terms&conditions')}</a></li>
          <li><a href="#">{t('translation.legalNotice')}</a></li>
          <li><a href="#">{t('translation.dataPrivacy')}</a></li>
        </ul>
      </div>
    </>
  );
}

export default Copyright;
