import ResetPasswordForm from '../components/security/ResetPasswordForm';
import LoginThumb from '../components/security/LoginThumb';
import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {validateResetPasswordHash} from '../features/password/validateResetPassword';
import ResetPasswordExpired from '../components/security/ResetPasswordExpired';

function ResetPassword() {
  const [valideHash, setValidHash] = useState(null);
  const {hash} = useParams();
  useEffect(async () => {
    const res = await validateResetPasswordHash(hash);
    if(res.status === 200) {
      setValidHash(true);
    } else {
      setValidHash(false);
    }
  });
  return (
    <div className="grid grid-cols-12">
      <div className="h-full min-h-full order-last col-span-12 md:order-first md:col-span-7 lg:col-span-8 bg-white flex content-center">
        <LoginThumb />
      </div>
      <div className="col-span-12 md:col-span-5 lg:col-span-4 flex content-center">
        {valideHash ?
          <ResetPasswordForm hash={hash}/>
          : <ResetPasswordExpired />
        }
      </div>
    </div>
  );
}

export default ResetPassword;

