import PropTypes from 'prop-types';
import {formatNumberThreeDigits} from '../../helpers/util';
import ImageUnavailable from '../../assets/images/image-not-available.png';
import {useTranslation} from 'react-i18next';

export default function ProductListItem({product, onViewDetail}) {
  const {t} = useTranslation();
  return (
    <div className="bg-white border-2-dee9ff rounded-[20px] hover:shadow-lg cursor-pointer overflow-hidden"
      onClick={() => onViewDetail(product)}>
      {product.images && product.images.length ?
        <div className="min-h-[12rem] max-h-[12rem] text-center bg-black">
          <img className="inline-block h-[12rem] max-h-full object-contain" src={product.images[0].contentUrl} alt=""/>
        </div> :
        <div className="min-h-[12rem] max-h-[12rem] font-montserrat flex flex-col items-center bg-gray-300">
          <img className="w-12 my-2" src={ImageUnavailable} alt=""/>
          <p>{t('translation.imageNotAvailable')}</p>
        </div>}
      <div className="my-3">
        <h3 className="font-montserrat font-semibold text-center text-base">{product.title}</h3>
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap gap-2 px-4">
        {product.engine &&
          <span className='bg-gray-200 rounded text-black p-2 whitespace-nowrap font-montserrat font-semibold text-xs'>
            {product.engine}</span>}
        {product.mileage &&
          <span
            className='bg-gray-200 rounded text-black p-2 whitespace-nowrap font-montserrat font-semiboldtext-xs'>
            {formatNumberThreeDigits(product.mileage)} km</span>}
        {product.transmission &&
          <span className='bg-gray-200 rounded text-black p-2 whitespace-nowrap font-montserrat font-semibold text-xs'>
            {t(`translation.${product.transmission}`)}
          </span>}
      </div>
      <div className="my-4">
        <h3 className="text-center text-black font-montserrat font-semibold capitalize text-sm ">{t('translation.price')}</h3>
        <div className="mb-2">
          <p className="font-bold font-montserrat text-primary-blue text-center text-lg uppercase">
            {formatNumberThreeDigits(product.price)} dhs</p>
        </div>
      </div>
    </div>
  );
}

ProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
  onViewDetail: PropTypes.func
};
