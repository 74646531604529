import {useTranslation} from 'react-i18next';

function NewsletterForm() {
  const {t} = useTranslation();
  return (
    <>
      <form className="col-span-2 mx-0 sm:mx-4 my-4 flex font-axis-bold text-white sm:w-auto w-full">
        <input className="newsletter-input"
          placeholder=""/>
        <button className="newsletter-button">{t('translation.subscribe')}</button>
      </form>
    </>
  );
}

export default NewsletterForm;
