import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import Apple from '../assets/images/apple.png';
import Huawei from '../assets/images/huawei.png';
import Alcatel from '../assets/images/alcatel.png';
import BlackView from '../assets/images/blackview.png';
import Nokia from '../assets/images/nokia.png';
import OnePlus from '../assets/images/oneplus.png';
import Samsung from '../assets/images/samsung.png';
import Oppo from '../assets/images/oppo.png';
import Xiaomi from '../assets/images/xiaomi.png';

function BrandsBanner() {
  return (
    <div className="bg-white p-5">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2,
          }, // when window width is >= 768px
          1024: {
            width: 1024,
            slidesPerView: 5,
          }, 1366: {
            width: 1366,
            slidesPerView: 6,
          },1400: {
            width: 1400,
            slidesPerView: 9,
          },
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        autoPlay={true}
        className="container"
      >
        <SwiperSlide><img className="" src={Samsung} alt="Samsung"/></SwiperSlide>
        <SwiperSlide><img className="" src={Apple} alt="Apple"/></SwiperSlide>
        <SwiperSlide><img className="" src={Xiaomi} alt="Xiaomi"/></SwiperSlide>
        <SwiperSlide><img className="" src={Huawei} alt="Huawei"/></SwiperSlide>
        <SwiperSlide><img className="" src={OnePlus} alt="OnePlus"/></SwiperSlide>
        <SwiperSlide><img className="" src={Nokia} alt="Nokia"/></SwiperSlide>
        <SwiperSlide><img className="" src={Oppo} alt="Oppo"/></SwiperSlide>
        <SwiperSlide><img className="" src={Alcatel} alt="Alcatel"/></SwiperSlide>
        <SwiperSlide><img className="" src={BlackView} alt="BlackView"/></SwiperSlide>

      </Swiper>
    </div>
  );
}

export default BrandsBanner;