import {API_BASE_URL} from '../../services/axiosInstance';
import axios from 'axios';

export function authenticate(credentials) {
  return axios({
    method: 'POST',
    url: API_BASE_URL+'/login_check',
    headers: {'content-type': 'application/json'},
    data: JSON.stringify(credentials),
  });
}
