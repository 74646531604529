import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {useEffect, useState} from 'react';
import {fetchCampaigns} from '../../features/campaign/campaignAPI';
import {addProductsToCampaign} from '../../features/products/productAPI';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export default function AddProductsToCampaignDialog({
  customCSSClasses,
  buttonLabel,
  dialogContentText,
  dialogTitle,
  filters
}) {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(-1);

  useEffect(() => {
    const fetch = async () => {
      const data = await fetchCampaigns(1, 100, 'order[createdAt]=desc', 'status=draft');
      setCampaigns([{'@id': -1, name: t('translation.selectACampaign')}, ...data['hydra:member']]);
    };
    fetch();

    parseInt(filters['price[gte]']);
    parseInt(filters['price[lte]']);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddToCampaign = async () => {
    if (selectedCampaign == -1) {
      return;
    }

    try {
      filters['minYear'] = filters['year[gte]'];
      filters['maxYear'] = filters['year[lte]'];
      filters['minPrice'] = filters['price[gte]'];
      filters['maxPrice'] = filters['price[lte]'];
      filters['minMileage'] = filters['mileage[gte]'];
      filters['maxMileage'] = filters['mileage[lte]'];
      

      delete filters['year[gte]'];
      delete filters['year[lte]'];
      delete filters['price[gte]'];
      delete filters['price[lte]'];
      delete filters['mileage[gte]'];
      delete filters['mileage[lte]'];



      const response = await addProductsToCampaign(selectedCampaign, filters);
      if (response.status === 201) {
        handleClose();
        toast.success(`${response.data.newAddedProductsCount} ${t('translation.leadsAreAddedToYourCampaign')}`);
      } else {
        toast.error(response.data['hydra:error']);
      }
    } catch (ex) {
      toast.error(`${t('translation.errorOccurred')}`);
    }
  };

  const onCampaignChange = (e) => {
    setSelectedCampaign(e.target.value);
  };

  return (
    <>
      <button className={`${customCSSClasses} flex items-center`} onClick={handleClickOpen}>
        <StarBorderIcon/> {buttonLabel}
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContentText}
          </DialogContentText>
          <select
            className="width-full p-2 border-2 border-gray-500 font-montserrat text-sm font-semibold  bg-white mb-4"
            id="brand"
            onChange={onCampaignChange}
            value={selectedCampaign || ''}>
            {campaigns && campaigns.length > 0 && campaigns.map(campaign => (
              <option value={campaign['@id'] || null} key={campaign['@id'] || campaign.name}>
                {campaign.name}
              </option>))}
          </select> <br/>
                    ou <Link to="/campaigns/create" className="blue-button">Créer une nouvelle</Link>
          {selectedCampaign == -1 &&
                        <p style={{color: 'red'}} className="mt-4">Veuillez sélectionner une campagne</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <Button onClick={handleAddToCampaign}>Envoyer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddProductsToCampaignDialog.propTypes = {
  customCSSClasses: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  dialogContentText: PropTypes.string,
  dialogTitle: PropTypes.string,
  filters: PropTypes.object.isRequired,
};
