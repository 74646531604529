import loginThumb from '../../assets/images/login-thumb.svg';

function LoginThumb() {
  return (
    <div className="flex align-center bg-white">
      <img className="md:h-screen sm:h-auto" src={loginThumb} alt=""/>
    </div>
  );
}

export default LoginThumb;
