import { Formik } from 'formik';
import * as Yup from 'yup';
import ocarzProLogo from '../../assets/images/ocarz-pro.svg';
import switchyproLogo from '../../assets/images/switchy.png';
import {changePassword} from '../../features/password/changeAPI';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';



export default function ChangePasswordForm() {
  const [violations, setViolations] = useState([]);
  const OCARZPRO = process.env.REACT_APP_TARGETED_PLATFORM === 'ocarzpro';
  const SWITCHYPRO = process.env.REACT_APP_TARGETED_PLATFORM === 'switchypro';
  const {t} = useTranslation();

  const Schema = Yup.object().shape({
    currentPassword: Yup.string().required(`${t('translation.requiredField')}`),
    newPassword: Yup.string().required(`${t('translation.requiredField')}`),
    reNewPassword: Yup.string().when('newPassword', {
      is: val => (val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        `${t('translation.passwordMatchesMessageValidation')}`
      )
    })
  });
  return (
    <div className="flex items-center sm:justify-center justify-start flex-col md:py-18 sm:max-h-max">
      <div className="lg:mb-22 my-24">
        <Link to="/">
          <img src={OCARZPRO && ocarzProLogo || SWITCHYPRO && switchyproLogo} className="mx-auto max-w-[200px]" alt=""/>
        </Link>
      </div>
      <h1 className="font-montserrat text-white text-center text-2xl uppercase font-semibold px-3">{t('translation.changeYourPassword')}</h1>
      <Formik
        initialValues={{currentPassword: '', newPassword: '', reNewPassword: ''}}
        validationSchema={Schema}
        onSubmit={async (values, {setSubmitting}) => {
          try {
            const response = await changePassword(values.currentPassword, values.newPassword, values.reNewPassword);
            setSubmitting(false);
            if (response.status === 200) {
              setSubmitting(false);
              toast.success(response.data.message);
              setTimeout(() => {
                // logout();
              }, 3000);
            } else if (response.status === 422 && response.data.violations) {
              const violations = {};
              for (const v of response.data.violations) {
                violations[v.propertyPath] = v.message;
              }
              setViolations(violations);
            } else {
              toast.error(response && response.data && response.data.message ? response.data.message : `${t('translation.anErrorHasOccurred')}`);
            }
          } catch (ex) {
            console.log('ex',ex.message);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="flex items-center flex-col text-white py-4 w-full p-10" onSubmit={handleSubmit}>
            <input
              type="password"
              name="currentPassword"
              placeholder={t('translation.currentPassword')}
              className={`bg-white p-3 rounded my-3 text-gray-500 w-full ${errors.currentPassword && 'border-2 border-red-500'} `}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentPassword}
            />
            {(touched.currentPassword && errors.currentPassword || violations.currentPassword) &&
             <span className="bg-white rounded px-2 py-2 mb-2 text-center" style={{color: 'red'}}>
               {errors.currentPassword ? errors.currentPassword : violations.currentPassword}
             </span>
            }
            <input
              type="password"
              name="newPassword"
              placeholder={t('translation.newPassword')}
              className={`bg-white p-3 rounded my-3 text-gray-500 w-full ${errors.newPassword && 'border-2 border-red-500'} `}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
            />
            {(touched.newPassword && errors.newPassword || violations.newPassword) &&
              <span className="bg-white rounded px-2 py-2 mb-2 text-center" style={{color: 'red'}}>
                {errors.newPassword ? errors.newPassword : violations.newPassword}
              </span>
            }
            <input
              type="password"
              name="reNewPassword"
              placeholder={t('translation.confirmNewPassword')}
              className={`bg-white p-3 rounded my-3 text-gray-500 w-full ${errors.reNewPassword && 'border-2 border-red-500'} `}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.reNewPassword}
            />
            {(touched.reNewPassword && errors.reNewPassword || violations.reNewPassword) &&
              <span className="bg-white rounded px-2 py-2 mb-2 text-center" style={{color: 'red'}}>
                {errors.reNewPassword ? errors.reNewPassword : violations.reNewPassword}
              </span>
            }
            <button className="btn-form" type="submit" disabled={isSubmitting}>{t('translation.send')}</button>
          </form>
        )}
      </Formik>
    </div>
  );
}
