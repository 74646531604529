import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeIcon from '@mui/icons-material/Badge';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GarageIcon from '@mui/icons-material/Garage';
import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import {fetchProfileAsync, selectProfile} from '../features/profile/profileSlice';
import {useTranslation} from 'react-i18next';

export default function ProfileInfo() {
  const dispatch = useDispatch();
  const me = useSelector(selectProfile);
  const {t} = useTranslation();

  useEffect(() => {
    if (!me) {
      dispatch(fetchProfileAsync());
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '90vh',
          background: '#FFFFFF',
          px: '2rem',
          display: 'flex',
          justifyContent: 'center',
          flexDirection:'column',
          alignItems:'center'
        }}
      >
        {me && (
          <>
            <>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {t('translation.yourProfile')}
                  </ListSubheader>
                }
              >
                <List
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <ListItemIcon>
                    <BadgeIcon fontSize="small" />
                    <span className="text-gray-500 font-normal px-4">
                      {t('translation.firstLastName')}
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${me.firstName === null ? '' : me.firstName} ${me.lastName === null ? '' : me.lastName}`}
                  />
                </List>
                <List
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <ListItemIcon>
                    <AlternateEmailIcon fontSize="small" />
                    <span className="text-gray-500 font-normal px-4">
                      {t('translation.email')}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={me.email} />
                </List>
                <List
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <ListItemIcon>
                    <PhoneIphoneIcon fontSize="small" />
                    <span className="text-gray-500 font-normal px-4">
                      {t('translation.phoneNumber')}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={me.phone === null ? '' : me.phone} />
                </List>
                <List
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <ListItemIcon>
                    <ApartmentIcon fontSize="small" />
                    <span className="text-gray-500 font-normal px-4">
                      {t('translation.entreprise')}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={me.company === null ? '' : me.company} />
                </List>
                <List
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <ListItemIcon>
                    <GarageIcon fontSize="small" />
                    <span className="text-gray-500 font-normal px-4">
                      {t('translation.sector')}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={me.type === null ? '' : me.type} />
                </List>
              </List>
            </>
            <Box>
              <Button variant="contained" href="/profile/edit">
                {t('translation.edit')}
              </Button>
            </Box>
          </>
        )}
      </Box>

    </>
  );
}
