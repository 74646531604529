import {Link} from 'react-router-dom';
import cars from '../../assets/images/cars.png';
import switchyproHeroImage from '../../assets/images/xspro-hero-image.png';
import {useTranslation} from 'react-i18next';

// eslint-disable-next-line no-undef
const TARGETED_PLATFORM = process.env.REACT_APP_TARGETED_PLATFORM;
const OCARZPRO = process.env.REACT_APP_TARGETED_PLATFORM.toLowerCase() === 'ocarzpro';
const SWITCHYPRO = process.env.REACT_APP_TARGETED_PLATFORM.toLowerCase() === 'switchypro';

function HeroSection() {
  const {t} = useTranslation();
  return (
    <>
      <div className=" bg-white rounded-tl-md rounded-tr-md flex justify-center items-center flex-col xl:px-32 px-4 py-5">
        <div>
          <h1 className="font-axis-bold text-3xl text-black text-center py-5 my-5 sm:w-[60%] w-full m-auto block">{t(`${TARGETED_PLATFORM}.heroSectionMainTitle`)}</h1>
        </div>
        <div className="mb-5">
          <img src={OCARZPRO && cars || SWITCHYPRO && switchyproHeroImage} alt=""/>
        </div>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-16 text-left sm:text-right px-0 sm:px-20">
          <div>
            <h3 className="text-2xl font-axis-bold uppercase">{t(`${TARGETED_PLATFORM}.leftBlockTitle`)}</h3>
            <p className="text-left sm:text-right font-futura-light">{t(`${TARGETED_PLATFORM}.leftBlockDesc`)}</p>
            <div className="flex sm:flex-row flex-col justify-end items-center gap-16">
              <Link to='/login' className="font-axis-bold text-sm blue-gradient-btn">{t(`${TARGETED_PLATFORM}.leftBlockBtnLabel`)}</Link>
            </div>
          </div>
          <div className="text-left">
            <h3 className="text-2xl font-axis-bold uppercase">{t(`${TARGETED_PLATFORM}.rightBlockTitle`)}</h3>
            <p className="text-left font-futura-light">{t(`${TARGETED_PLATFORM}.rightBlockDesc`)}</p>
            <div className="flex sm:flex-row flex-col justify-center items-center gap-16">
              <Link to='/login' className="font-axis-bold text-sm yellow-gradient-btn">{t(`${TARGETED_PLATFORM}.rightBlockBtnLabel`)}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
