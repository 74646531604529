import ChangePasswordForm from '../components/security/ChangePasswordForm';
import LoginThumb from '../components/security/LoginThumb';
import Header from '../components/common/Header';

function ChangePassword() {
  return (
    <>
      <Header/>
      <div className="grid grid-cols-12">
        <div className="h-auto order-last col-span-12 md:order-first md:h-screen md:h-auto md:col-span-7 lg:col-span-8 bg-white flex content-center">
          <LoginThumb />
        </div>
        <div className="col-span-12 md:col-span-5 lg:col-span-4">
          <ChangePasswordForm />
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
