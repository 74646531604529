import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token'),
  status: 'idle',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    logout: (state) => {
      state.token = null;
      localStorage.removeItem('token');
    },
  },
});


export const selectToken = (state) => state.auth.token;

export const { setToken, logout } = authSlice.actions;

export default authSlice.reducer;
